import React, { Fragment, useEffect, useState } from 'react';
import 'animate.css'
import { FaUser, FaMobileAlt, FaUsers } from "react-icons/fa";
import { ImMail4 } from "react-icons/im";
import { BsShieldLockFill } from "react-icons/bs";
import RealEstate from '../../Assets/images/logo-real-estate-home-solution.jpg'
import { Slide, toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import { Link, useNavigate } from 'react-router-dom';
import { Form } from 'react-bootstrap';
import { MdRadioButtonChecked, MdSecurity } from 'react-icons/md';
import Axios from 'axios';
import Live_Base_Url from '../Common/Live_Base_Url';



const Register = () => {
    const Navigate = useNavigate();
    const [divshow, setDivShow] = useState(false); //for visit loginpage and registerpage 
    const [otp, setOTP] = useState('');
    const [first_name, setFirst_name] = useState('');
    const [last_name, setLast_name] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [password, setPassword] = useState('');
    const [password_confirmation, setPassword_confirmation] = useState('');
    const [loginEmail, setLoginEmail] = useState('');
    const [loginPassword, setLoginPassword] = useState('');
    const [user_type, setUser_type] = useState('Owner');
    const [phoneCheck, setPhoneCheck] = useState('');

    const [showOTPInput, setShowOTPInput] = useState(false);
    const [btnShow, setBtnShow] = useState(false);



    const auth = localStorage.getItem("Authorization")

    useEffect(() => {
        if (!auth) {
            localStorage.clear();
            Navigate("/register");
        } else {
            Navigate("/Dashboard/home")
        }
    }, [auth, Navigate]);


    const handleSendOtp = async () => {
        try {
            const { data } = await Axios.post(`${Live_Base_Url}/send-otp`, { phone });
            if (data?.success) {
                toast.info(data?.message, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    transition: Slide,
                });
                setShowOTPInput(true);
                setBtnShow(false);
                localStorage.removeItem('orderId');
                localStorage.setItem("orderId", data?.data?.orderId);
            } else {
                localStorage.removeItem('orderId');
            }
        } catch (error) {
            console.error("Error sending OTP:", error);
        }
    };

    const handleReSendOtp = async () => {
        try {
            const { data } = await Axios.post(`${Live_Base_Url}/send-otp`, { phone });

            if (data?.success) {
                toast.info("Re-send Otp to your mobile number", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    transition: Slide,
                });
                setShowOTPInput(true);
                localStorage.removeItem('orderId');
                localStorage.setItem("orderId", data?.data?.orderId);
            } else {
                localStorage.removeItem('orderId');
            }
        } catch (error) {
            console.error("Error resending OTP:", error);
        }
    };


    const handleVerifyOtp = async () => {
        const orderId = localStorage.getItem('orderId')?.toString();

        const payload = {
            phone: `${phone}`,
            otp: `${otp}`,
            orderId: `${orderId}`
        };

        try {
            const { data } = await Axios.post(`${Live_Base_Url}/verify-otp`, payload);
            if (data?.success) {
                toast.success(data?.message || "OTP verified successfully!", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    transition: Slide,
                });
                setPhoneCheck(phone);
                setShowOTPInput(false);

            } else {
                toast.error(data?.message || "OTP verification failed", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    transition: Slide,
                });
            }
        } catch (error) {
            console.error("Error verifying OTP:", error);
            toast.error("An error occurred while verifying OTP", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Slide,
            });
        }
    };

    const handleRegisteration = async (event) => {
        event.preventDefault()
        const payload = {
            first_name, last_name, password, password_confirmation, email, user_type, phone
        }
        if (password === password_confirmation) {
            console.log("Event fire1")
            if (payload.phone === phoneCheck) {
                try {
                    const response = await Axios.post(`${Live_Base_Url}/register`, payload)
                    console.log(response?.data);
                    if (response?.data?.success) {
                        toast.success("Registration Successfull", {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                            transition: Slide,
                        });
                        setFirst_name('');
                        setLast_name('');
                        setEmail('');
                        setPhone('');
                        setPassword('');
                        setPassword_confirmation('');
                        localStorage.setItem("Authorization", `Bearer ${response?.data?.access_token}`)
                        Navigate("/Dashboard/home");
                    }
                    else {
                        toast.error('Registration failed', {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                            transition: Slide,
                        });
                    }
                }
                catch (error) {
                    alert(error)
                }
            } else {
                toast.error("Phone Number Changed!", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    transition: Slide,
                });
            }
        } else {
            toast.error("password and confirm_password Does not match!", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Slide,
            });
        }

    }
    const handleLogin = async (event) => {
        event.preventDefault();
        const payload = {
            "email": loginEmail,
            "password": loginPassword
        }
        try {
            const response = await Axios.post(`${Live_Base_Url}/login`, payload);

            // Check for successful response
            if (response?.data?.success) {
                setLoginEmail('');
                setLoginPassword('');
                localStorage.setItem('Authorization', `Bearer ${response?.data?.access_token}`);
                Navigate("/Dashboard/home");
            } else {
                // Handle response failure with specific message
                toast.error(response?.data?.message || "Login Failed!", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    transition: Slide,
                });
                setLoginEmail('');
                setLoginPassword('');
            }
        } catch (error) {
            if (error.response) {
                toast.error(`Error: ${error.response.data?.message || "An error occurred on the server."}`, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    transition: Slide,
                });
            } else if (error.request) {
                toast.error("Network error: No response from the server. Please check your connection.", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    transition: Slide,
                });
            } else {
                toast.error(`Unexpected error: ${error.message}`, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    transition: Slide,
                });
            }

            setLoginEmail('');
            setLoginPassword('');

            console.error('Error during login:', error.message);
        }


    }

    return (
        <Fragment>
            <div className="registerbody container-fluid">
                <div className={`register-container ${divshow ? 'animate__animated animate__fadeOutRight d-none' : 'animate__animated animate__fadeInRight'} m-3`}>
                    <div className="register-header">
                        <Link to="/"><img src={RealEstate} className='my-2' height={"100px"} style={{ mixBlendMode: "revert-layer" }} alt="foto" /></Link><br />
                        Register
                    </div>
                    <form className="register-form" onSubmit={handleRegisteration} method='post'>
                        <div className='d-flex justify-content-center align-items-center'>
                            <div id="role">
                                <button type="button" className={`btn ${user_type === 'Owner' ? 'active' : ''}`} onClick={() => {
                                    setUser_type('Owner');
                                }}>
                                    Owner
                                </button>

                                <button type="button" className={`btn ${user_type === 'Broker' ? 'active' : ''}`} onClick={() => {
                                    setUser_type('Broker');
                                }}>
                                    Broker
                                </button>

                                <button type="button" className={`btn ${user_type === 'Buyer' ? 'active' : ''}`} onClick={() => {
                                    setUser_type('Buyer');
                                }}>
                                    Buyer
                                </button>
                            </div>
                        </div>
                        <span className='d-flex gap-3 justify-content-between'>
                            <label htmlFor="firstname"><FaUser /><input type="text" placeholder={`firstname`} name='firstname' value={first_name} required onChange={(e) => { setFirst_name(e.target.value) }} /></label>
                            <label htmlFor="lastname"><FaUsers /><input type="text" placeholder={`lastname`} name='lastname' value={last_name} required onChange={(e) => { setLast_name(e.target.value) }} /></label>
                        </span>
                        <label htmlFor="mobile"><FaMobileAlt />
                            <input type="tel" placeholder="Phone Number" value={phone} name='mobile' pattern="(\+91)?[6-9][0-9]{9}|0[6-9][0-9]{9}|[6-9][0-9]{9}" onChange={(e) => {
                                setPhone(e.target.value);
                                setBtnShow(true);
                                setShowOTPInput(false);
                            }} required />
                            <h1 className={`btn btn-warning btn-sm ${btnShow ? "d-inline" : "d-none"}`} onClick={handleSendOtp}>Send OTP</h1>
                            <h1 className={`btn btn-warning btn-sm ${showOTPInput ? "d-inline" : "d-none"}`} onClick={handleReSendOtp} style={{ textWrap: "nowrap" }}>Resend OTP</h1>
                        </label>
                        {showOTPInput && (
                            <Form.Group className="mt-3" controlId="formOTP">
                                <label htmlFor="mobileotp"><MdRadioButtonChecked />
                                    <input
                                        type="text"
                                        placeholder={`Enter OTP`}
                                        value={otp}
                                        name='mobileotp'
                                        required
                                        onChange={(e) => { setOTP(e.target.value) }} />
                                    <h1 className='btn btn-success btn-sm' onClick={handleVerifyOtp}>Verify</h1>
                                </label>
                            </Form.Group>
                        )}

                        <label htmlFor="email"><ImMail4 />
                            <input type="email" placeholder="Your email" value={email} name='email' required onChange={(e) => { setEmail(e.target.value); }} />
                        </label>
                        <label htmlFor="password"><BsShieldLockFill />
                            <input type="password" placeholder="Password" pattern=".{8,}" minLength="8"
                                title="Password must be at least 8 characters long" name='password' value={password} onChange={(e) => {
                                    setPassword(e.target.value);
                                }} required />
                        </label>
                        <label htmlFor="confirmationpassword"><MdSecurity />
                            <input type="password" placeholder="Re-type password" name='confirmationpassword' value={password_confirmation} onChange={(e) => {
                                setPassword_confirmation(e.target.value);
                            }} required />
                        </label>
                        <div className='d-flex ms-1 py-2' style={{ justifyContent: "start" }}><input type="checkbox" className='mx-3' /><span >Remember me </span>
                        </div>
                        <h5 className='text-end'> Already have an account? <span className='text-primary' style={{ cursor: "pointer" }} onClick={() => { setDivShow(!divshow) }}>Login now</span>
                        </h5>
                        <div className="container text-center">
                            <button type="submit" className="register-button w-50">REGISTER</button>
                        </div>
                    </form>
                </div>


                {/* LoginPage */}


                <div className={`register-container ${divshow ? 'animate__animated animate__fadeInLeft' : 'animate__animated animate__fadeOutLeft d-none'} m-3`}>
                    <div className="register-header">
                        <Link to={`/`}><img src={RealEstate} className='my-4' height={"100px"} style={{ mixBlendMode: "revert-layer" }} alt="foto" /></Link><br />
                        LOGIN YOUR ACCOUNT
                    </div>

                    <form className="register-form" onSubmit={handleLogin} method='post'>

                        <label htmlFor="email"><ImMail4 /><input type="text" placeholder={`Your email`} required value={loginEmail} onChange={(e) => {
                            setLoginEmail(e.target.value);
                        }} /></label>
                        <label htmlFor="password"><BsShieldLockFill /><input type="password" placeholder="Password" required value={loginPassword} onChange={(e) => {
                            setLoginPassword(e.target.value);
                        }} /></label>
                        <div className='d-flex justify-content-start ms-1 py-2'>
                            <input type="checkbox" className='mx-3' name='check' id='check' /><span id='check' name='check'>Remember me</span>
                        </div>
                        <div className="container text-center">
                            <h5 className='text-end mt-4 mb-2'>Don't have an account?
                                <span className='text-primary' style={{ cursor: "pointer" }} onClick={() => { setDivShow(!divshow) }}> create now</span>
                            </h5>
                            <h6 className='text-end'>Forgot Password?
                                <span className='text-primary' style={{ cursor: "pointer" }}> click here</span>
                            </h6>
                            <button className="register-button w-50">LOGIN</button>
                        </div>
                    </form>
                </div>
                <ToastContainer />
            </div>
        </Fragment>
    )
}

export default Register
