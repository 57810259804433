import React, { Fragment } from 'react'

function VideoProperty() {
    return (
        <Fragment>
            <div className="floor-plans-full-container container-fluid bg-light rounded-4  mt-4">
                <h3 className='p-4'>Video</h3>
                <div className="container">
                    <div>
                        <video width="100%" onPlay={true} controls>
                            <source src="https://youtu.be/4jnzf1yj48M" type="video/mp4" />
                            Your browser does not support the video tag.
                        </video>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default VideoProperty
