import React, { Fragment } from 'react'
import { FaAt } from 'react-icons/fa';
import pic1 from '../../Assets/images/Blog1.jpg';
import pic2 from '../../Assets/images/Blog2.jpg';
import pic3 from '../../Assets/images/Blog3.jpg';
import pic4 from '../../Assets/images/blog4.jpg';
import { BiSolidDislike, BiSolidLike } from 'react-icons/bi';
import { LuArrowUpRight } from 'react-icons/lu';

function PropertyReview() {

    const data = [
        {
            id: 1,
            name: "John Doe",
            rating: 4.5,
            review: " John was also super friendly and passionate about Beşiktaş and Istanbul. Every single thing we tried with John was delicious! Found some awesome places we would definitely ",
        },
        {
            id: 2,
            name: "Herosima",
            rating: 4.5,
            review: "Every single thing we tried with John was delicious! Found some awesome places we would definitely go back to on our trip. John was also super friendly and passionate about Beşiktaş and Istanbul.",
        },
        {
            id: 3,
            name: "Chetanya",
            rating: 4.5,
            review: "Every single thing we tried with John was delicious! Found some awesome places we would definitely go back to on our trip. John was also super friendly and passionate about Beşiktaş and Istanbul.",
        },
    ]

    return (
        <Fragment>
            <div className="container-fluid property-review-full-container bg-light mt-4 rounded-4">
                <div className="property-review-container">
                    <div className="property-review-header ">
                        <h6>⭐ 5.0 · 3 reviews</h6>
                        <p>
                            <button className='btn'>sorted</button>
                            <button className='btn'>Newest</button>
                        </p>
                    </div>
                    {data.map((d, i) => {
                        return (<>
                            <div className="property-review-first-content">
                                <div className="property-review-first-content-left">
                                    <div className='faat pt-0'>
                                        <FaAt />
                                    </div>
                                    <p className='d-flex pt-2 flex-column'>
                                        <span className='fs-5'>{d.name}</span> <span className='fs-6'>{d.id} March 2022</span>
                                    </p>
                                </div>
                                <div className="property-review-first-content-right">
                                    ⭐⭐⭐⭐⭐
                                </div>
                            </div>
                            <div className="property-review-second-content">
                                <p style={{textAlign:'justify'}}>
                                    {d.review}
                                </p>
                                <div className='w-100 mb-3'>
                                    <img className='' src={pic1} alt="" />
                                    <img className='' src={pic2} alt="" />
                                    <img className='' src={pic3} alt="" />
                                    <img className='' src={pic4} alt="" />
                                </div>
                                <p className='d-flex justify-content-center gap-5'>
                                    <span className='btn btn-outline-primary'><BiSolidLike /> Helpful</span>
                                    <span className='btn btn-outline-primary'><BiSolidDislike /> Not Helpful</span>
                                </p>
                                <hr />
                            </div>
                        </>
                        )
                    })}
                    <div className="container text-center">
                        <button className="submit-btn">Show All 316 reviews<LuArrowUpRight /></button>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default PropertyReview
