import React, { Fragment } from 'react'
import { RiArrowRightUpLine } from 'react-icons/ri';
import sell1 from '../../Assets/images/sell1.jpg';
import sell2 from '../../Assets/images/sell2.jpg';
import sell3 from '../../Assets/images/sell3.png';

function Selling() {
    return (
        <Fragment>
            <div className="selling-full-container container-fluid">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 order-2 order-lg-1">
                            <div className="selling-content">
                                <h2 className="pe-lg-5 p-2">Let’s find the right selling option for you</h2>
                                <p>
                                    As the complexity of buildings to increase, the field of architecture
                                </p>
                                <ul className='text-start'>
                                    <li>Find excellent deals</li>
                                    <li>Friendly host & Fast support</li>
                                    <li>List your own property</li>
                                </ul>
                                <button className="btn">Learn More <RiArrowRightUpLine /> </button>
                            </div>
                        </div>
                        <div className="col-lg-6 order-1 order-lg-2">
                            <div className="selling-image">
                                <img className='img-1' src={sell1} alt="" />
                                <img className='img-2' src={sell2} alt="" />
                                <img className='img-3' src={sell3} alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default Selling
