import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle';
import './Components/Common/Style.css'


import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './Components/Home/Home';
import Register from './Components/Registration/Register';
import HomeList from './Components/Listing/HomeList';
import HomeProperty from './Components/Property/HomeProperty';
import Dashboard from './Components/Dashboard/Dashboard';
import DashboardHome from './Components/Dashboard/DashboardHome/DashboardHome';
import PropertyForm from './Components/Dashboard/AddNewProperty/PropertyForm';
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path='*' element={<Home />} />
        <Route path="/default-list/:id" element={<HomeList></HomeList>} />
        <Route path="/single_v1" element={<HomeProperty />} />

        <Route path='/Dashboard' element={<Dashboard />}>
          <Route path='home' element={<Suspense fallback={"loading"}><DashboardHome></DashboardHome></Suspense>}></Route>
          <Route path='add-new-property' element={<Suspense fallback={"loading"}><PropertyForm></PropertyForm></Suspense>}></Route>
        </Route>

        {/* Register part */}
        <Route path="/register" element={<Register />} />
      </Routes>
    </Router>
  </React.StrictMode>
);
