import React, { Fragment } from 'react'
import ReactOwlCarousel from 'react-owl-carousel';
import company1 from '../../Assets/images/1.png';
import company2 from '../../Assets/images/2.png';
import company3 from '../../Assets/images/3.png';
import company4 from '../../Assets/images/4.png';
import company5 from '../../Assets/images/5.png';
import company6 from '../../Assets/images/6.png';
import { BiSolidPhoneCall } from 'react-icons/bi';
import { RiArrowRightUpLine } from 'react-icons/ri';


function Company() {

    const options = {
        loop: true,
        autoplay: true,
        autoplayTimeout: 2500,
        nav: false,
        dots: false,
        responsive: {
            0: {
                items: 3
            },
            600: {
                items: 4
            },
            1000: {
                items: 5
            },
            1200: {
                items: 6
            }
        }
    };

    return (
        <Fragment>
            <div className="company-full-container container-fluid ">
                <div className="container pb-4">
                    <h2 className='h3 text-center' >Trusted by the world’s best</h2>
                </div>
                <div className="container">

                    <ReactOwlCarousel className='owl-theme owl-carousel' {...options}>
                        <div className="item">
                            <img className='w-lg-25 w-50' src={company1} alt="" />
                        </div>
                        <div className="item">
                            <img className='w-lg-25 w-50' src={company2} alt="" />
                        </div>
                        <div className="item">
                            <img className='w-lg-25 w-50' src={company3} alt="" />
                        </div>
                        <div className="item">
                            <img className='w-lg-25 w-50' src={company4} alt="" />
                        </div>
                        <div className="item">
                            <img className='w-lg-25 w-50' src={company5} alt="" />
                        </div>
                        <div className="item">
                            <img className='w-lg-25 w-50' src={company6} alt="" />
                        </div>
                    </ReactOwlCarousel>
                </div>
            </div>
            <div className="container-fluid p-5" style={{ background: "rgba(0,0,0,.2)" }}>
                <div className="container d-lg-flex justify-content-between">
                    <div className="need-help text-start">
                        <h1>Need help? Talk to our expert.</h1>
                        <p>
                            Talk to our experts or Browse through more properties.
                        </p>
                    </div>
                    <div className="d-flex">
                        <button className='btn btn-outline-dark h-50 m-2'>Contact Us <RiArrowRightUpLine /></button>
                        <button className='btn btn-outline-dark h-50 m-2'> <BiSolidPhoneCall /> 9632184728</button>
                    </div>
                    

                </div>
            </div>
        </Fragment>
    )
}

export default Company
