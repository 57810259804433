import React, { Fragment } from 'react';
import virtual from '../../Assets/images/virtual-tour.jpg'

function VirtualTour() {
    return (
        <Fragment>
            <div className="floor-plans-full-container container-fluid bg-light rounded-4  mt-4">
                <h3 className='p-4'>360<sup>0</sup> Virtual Tour </h3>
                <div className="container">
                    <div>
                        <img className='w-100 pb-3' src={virtual} alt="" />
                    </div>
                </div>
            </div>
        </Fragment>
    )
}
export default VirtualTour
