import React, { useState } from 'react';
import { FaRegQuestionCircle } from 'react-icons/fa';

const PricingAndOthers = ({ setActiveTab }) => {
    const [ownership, setOwnship] = useState('freehold');


    return (
        <div className="pricing-and-Other">
            <p className='step5'>Step 5</p>
            <h4 className='whatkindh4'>Add pricing and details...</h4>
            <div className="pricing-and-other-input">


                <div className="container my-3">
                    <div className="ownership-section mb-3">
                        <h6>Ownership <span title='ownership has catagories to choose'><FaRegQuestionCircle /></span></h6>
                        <div className="btn-groups">
                            <button className={`btn ${ownership === 'freehold' ? 'active' : ''}`} onClick={() => {
                                setOwnship('freehold');
                            }}>Freehold</button>
                            <button className={`btn ${ownership === 'leasehold' ? 'active' : ''}`} onClick={() => {
                                setOwnship('leasehold');
                            }}>Leasehold</button>
                            <button className={`btn ${ownership === 'co-operative' ? 'active' : ''}`} onClick={() => {
                                setOwnship('co-operative');
                            }}>Co-operative society</button>
                            <button className={`btn ${ownership === 'power' ? 'active' : ''}`} onClick={() => {
                                setOwnship('power');
                            }}>Power of Attorney</button>
                        </div>
                    </div>

                    <div className="price-details-section mb-3">
                        <label className="form-label">Price Details</label>
                        <div className="row">
                            <div className="col-md-6 mb-2 form-floating">
                                <input className="form-control" id="floatinginput" />
                                <label className="ms-4" for="floatingTextarea">₹ Expected Price</label>
                            </div>
                            <div className="col-md-6 mb-2 form-floating">
                                <input className="form-control" id="floatinginput" />
                                <label className="ms-4" for="floatingTextarea">₹ Price per sq.ft.</label>
                            </div>

                        </div>
                        <p className="price-text">₹ One Lakh Twenty Three Thousand Four Hundred and Fifty Six (₹ 100 per sq.ft.)</p>
                        <div className="form-check d-inline-block me-3">
                            <input className="form-check-input" type="checkbox" value="" id="taxExcluded" checked />
                            <label className="form-check-label" htmlFor="taxExcluded">
                                Tax and Govt. charges excluded
                            </label>
                        </div>
                        <div className="form-check d-inline-block">
                            <input className="form-check-input" type="checkbox" value="" id="negotiable" />
                            <label className="form-check-label" htmlFor="negotiable">
                                Price Negotiable
                            </label>
                        </div>
                    </div>

                    <div className="additional-pricing-section mb-3">
                        <label className="form-label">Additional Pricing Details (Optional)</label>
                        <div className="row">
                            <div className="col-md-8 mb-2">
                                <div className="input-group">
                                    <input type="text" className="form-control" placeholder="Maintenance" />
                                    <button className="btn btn-outline-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                        Monthly
                                    </button>
                                    <ul className="dropdown-menu dropdown-menu-end">
                                        <li><button className="dropdown-item">Monthly</button></li>
                                        <li><button className="dropdown-item">Annually</button></li>
                                        <li><button className="dropdown-item">One Time</button></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-md-8 mb-2">
                                <div className="input-group">

                                    <input type="text" className="form-control" placeholder="Expected rental" />
                                    <button className="btn btn-outline-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                        Monthly
                                    </button>
                                    <ul className="dropdown-menu dropdown-menu-end">
                                        <li><button className="dropdown-item">Monthly</button></li>
                                        <li><button className="dropdown-item">Annually</button></li>
                                        <li><button className="dropdown-item">One Time</button></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-md-8 mb-2">
                                <div className="input-group">
                                    <input type="text" className="form-control" placeholder="Booking Amount" />
                                    <button className="btn btn-outline-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                        Monthly
                                    </button>
                                    <ul className="dropdown-menu dropdown-menu-end">
                                        <li><button className="dropdown-item">Monthly</button></li>
                                        <li><button className="dropdown-item">Annually</button></li>
                                        <li><button className="dropdown-item">One Time</button></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>



            </div>

            <div className='basic-btn d-flex gap-3'>
                <button className='submit-btn' onClick={() => setActiveTab('Videos&Photos')}>Back</button>
                <button className='submit-btn final-submit' onClick={() => setActiveTab('Pricing&Others')}>Final Submit</button>
            </div>
        </div>
    );
};

export default PricingAndOthers;
