import React, { Fragment } from 'react';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import ReactOwlCarousel from 'react-owl-carousel';
import { IoIosAmericanFootball, IoIosMegaphone, IoLogoFreebsdDevil, IoMdTrain } from "react-icons/io";
import { BiDonateHeart, BiLeaf, BiLogoBitcoin } from 'react-icons/bi';
function Explore() {
    const options = {
        loop: true,
        autoplay: true,
        autoplayTimeout: 1500,
        nav: true,
        dots: true,
        responsive: {
            0: {
                items: 1 // 1 item for screens smaller than 600px
            },
            600: {
                items: 2 // 2 items for screens 600px and up
            },
            1000: {
                items: 3 // 3 items for screens 1000px and up
            },
            1200: {
                items: 4 // 4 items for screens 1200px and up
            }
        }
    };
    return (
        <Fragment>
            <div className="container-fluid py-5">
                <div className="conatiner">
                    <section className="explore-section">
                        <h1>Explore Apartment Types</h1>
                        <p>
                            Get some Inspirations from 1800+ skills
                        </p>
                    </section>
                    {/* Owl Carousel */}
                    <section className='owl-section'>
                        <ReactOwlCarousel className='owl-theme' {...options}>
                            <div className='item'>
                                <div class="card  mb-3">
                                    <div class="card-header"><IoIosAmericanFootball /></div>
                                    <div class="card-body">
                                        <h5 class="card-title">House</h5>
                                        <p class="card-text">22 Property</p>
                                    </div>
                                </div>
                            </div>
                            <div className='item'>
                                <div class="card  mb-3">
                                    <div class="card-header"><IoIosMegaphone /></div>
                                    <div class="card-body">
                                        <h5 class="card-title">Apartment</h5>
                                        <p class="card-text">22 Apartment</p>
                                    </div>
                                </div>
                            </div>
                            <div className='item'>
                                <div class="card  mb-3">
                                    <div class="card-header"><IoLogoFreebsdDevil /></div>
                                    <div class="card-body">
                                        <h5 class="card-title">Office</h5>
                                        <p class="card-text">22 Apartment</p>
                                    </div>
                                </div>
                            </div>
                            <div className='item'>
                                <div class="card  mb-3">
                                    <div class="card-header"><IoMdTrain /></div>
                                    <div class="card-body">
                                        <h5 class="card-title">Villa</h5>
                                        <p class="card-text">22 Apartment</p>
                                    </div>
                                </div>
                            </div>
                            <div className='item'>
                                <div class="card  mb-3">
                                    <div class="card-header"><BiDonateHeart /></div>
                                    <div class="card-body">
                                        <h5 class="card-title">Town Home</h5>
                                        <p class="card-text">22 Apartment</p>
                                    </div>
                                </div>
                            </div>
                            <div className='item'>
                                <div class="card  mb-3">
                                    <div class="card-header"><BiLeaf /></div>
                                    <div class="card-body">
                                        <h5 class="card-title">Bunglow</h5>
                                        <p class="card-text">22 Apartment</p>
                                    </div>
                                </div>
                            </div>
                            <div className='item'>
                                <div class="card  mb-3">
                                    <div class="card-header"><BiLogoBitcoin /></div>
                                    <div class="card-body">
                                        <h5 class="card-title">Logft</h5>
                                        <p class="card-text">22 Apartment</p>
                                    </div>
                                </div>
                            </div>
                        </ReactOwlCarousel>
                    </section>
                </div>
            </div>
        </Fragment>
    )
}

export default Explore
