import React, { Fragment, useEffect, useState } from 'react'
import headerlogo from '../../Assets/images/logo-real-estate-home-solution.jpg';
import { CiMenuFries } from "react-icons/ci";
import { IoCloseCircleOutline } from "react-icons/io5";
import { FaChevronDown, FaChevronRight, FaRegUserCircle } from "react-icons/fa";
import { RiArrowRightUpLine } from "react-icons/ri";
import { Link } from 'react-router-dom';
function Header() {
    const [sidebarOpen, setSidebarOpen] = useState("");
    const [navBack, setNavBack] = useState('');
    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 110) {
                setNavBack('nav-back');
            } else {
                setNavBack('');
            }
        };
        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        }
    }, [])


    const sidebar = () => {
        setSidebarOpen("open");
    }
    const sidebarclose = () => {
        setSidebarOpen("");
    }
    return (
        <Fragment>
            <nav className={`${navBack} realnavbar navbar navbar-expand-lg d-flex justify-content-between fixed-top`}>
                <div className="container-fluid">
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="fs-1"><CiMenuFries /></span>
                    </button>
                    <a className="headerAnchor d-flex align-items-center" href="/">
                        <img src={headerlogo} alt="Logo"
                            className="d-inline-block align-text-top" style={{ width: '50px' }} />
                        <span className='fw-bold ps-2 '>Real Estate</span>
                    </a>
                    <div className="collapse navbar-collapse justify-content-lg-center" id="navbarSupportedContent">
                        <ul className="navbar-nav">
                            <li className="newnav-link nav-item">
                                Home
                            </li>
                            <li className="newnav-link nav-item">
                                Listing <FaChevronDown />
                                <div className='listing-div'>
                                    <div className="d-flex">
                                        <div className="grid-view p-3">
                                            <div className="grid-view-item">
                                                <h5> Grid View</h5>
                                                <p>
                                                    <br /> <a href="/default-list/6">Grid Default</a> <br /><br />
                                                    <a href="/default-list/4">Grid Full Width 3 Cols</a> <br /><br />
                                                    <a href="/default-list/3">Grid Full Width 4 Cols </a><br /><br />
                                                    <a href="/default-list/6">Grid Full Width 2 Cols </a><br /><br />
                                                    <a href="/default-list/12">Grid Full Width 1 Cols v1 </a><br /><br />
                                                </p>
                                            </div>
                                        </div>
                                        <div className="map-style p-3">
                                            <div className="map-style-item">
                                                <h5>Map Style</h5>
                                                <p>
                                                    <br /><a href="/">Header Map Style </a><br /><br />
                                                    <a href="/">Map V1 </a><br /><br />
                                                    <a href="/">Map V2 </a><br /><br />
                                                    <a href="/">Map V3 </a><br /><br />
                                                    <a href="/">Map V4 </a><br /><br />
                                                </p>
                                            </div>
                                        </div>
                                        <div className="map-style p-3">
                                            <div className="map-style-item">
                                                <h5>List View</h5>
                                                <p>
                                                    <br /><a href="/">List v1</a> <br /><br />
                                                    <a href="/">List All Style</a><br /><br />
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li className="newnav-link nav-item">
                                Property <FaChevronDown />
                                <div className='listing-div'>
                                    <div className="d-flex flex-column">
                                        <div className="map-style">
                                            <div className="inner-map-style">
                                                <ul>
                                                    <li className='d-flex'>
                                                        <p className='d-flex gap-5'><span className='fw-bold'> Agents </span><span><FaChevronRight /></span></p>
                                                        <ul className='list_ul'>
                                                            <li><a href="/">Agents</a></li>
                                                            <li><a href="/">Agent Single</a></li>
                                                            <li><a href="/">Agency</a></li>
                                                            <li><a href="/">Agency Single</a></li>
                                                        </ul>
                                                    </li>
                                                </ul>

                                            </div>
                                        </div>
                                        <div className="map-style">
                                            <div className="inner-map-style">
                                                <ul>
                                                    <li className='d-flex'>
                                                        <p className='d-flex gap-3'><span>Single Style </span><span><FaChevronRight /></span></p>
                                                        <ul className='list_ul'>
                                                            <li><a href="/single_v1">Single v1</a></li>
                                                        </ul>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="map-style">
                                            <div className="inner-map-style">
                                                <ul>
                                                    <li className='d-flex'>
                                                        <p className='d-flex gap-3'><span>Dashboard </span><span><FaChevronRight /></span></p>
                                                        <ul className='list_ul'>
                                                            <li><a href="/">Dashboard Home</a></li>
                                                            <li><a href="/">Message</a></li>
                                                        </ul>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li className="newnav-link nav-item">
                                Blog <FaChevronDown />
                                <div className='listing-div'>
                                    <div className="d-flex flex-column px-5">
                                        <div className="map-style pt-3">
                                            <div className="">
                                                <span> Blog list v1</span>
                                            </div>
                                        </div>
                                        <div className="map-style pt-3">
                                            <div className="">
                                                <span> Blog list v2</span>
                                            </div>
                                        </div>
                                        <div className="map-style pt-3">
                                            <div className="">
                                                <span> Blog list v3</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li className="newnav-link nav-item">
                                Pages <FaChevronDown />
                                <div className='listing-div'>
                                    <div className="d-flex flex-column px-5 ">
                                        <div className="map-style pt-3">
                                            <div className="">
                                                <span> About</span>
                                            </div>
                                        </div>
                                        <div className="map-style pt-3">
                                            <div className="">
                                                <span>Contact</span>
                                            </div>
                                        </div>
                                        <div className="map-style pt-3">
                                            <div className="">
                                                <span> Compate</span>
                                            </div>
                                        </div>
                                        <div className="map-style pt-3">
                                            <div className="">
                                                <span> Pricing</span>
                                            </div>
                                        </div>
                                        <div className="map-style pt-3">
                                            <div className="">
                                                <span> Faq</span>
                                            </div>
                                        </div>
                                        <div className="map-style pt-3">
                                            <div className="">
                                                <span><Link to={'/register'}>Login</Link> </span>
                                            </div>
                                        </div>
                                        <div className="map-style pt-3">
                                            <div className="">
                                                <span> <Link to={'/register'}>Register</Link></span>
                                            </div>
                                        </div>
                                        <div className="map-style pt-3">
                                            <div className="">
                                                <span>404</span>
                                            </div>
                                        </div>
                                        <div className="map-style pt-3">
                                            <div className="">
                                                <span> Invoice</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li className="newnav-item nav-item ps-5">
                                <p><Link to="/register">Login</Link> / <Link to="/register">Register</Link>
                                    <a href='/dashboard/add-new-property' className='anchor-btn btn border-light rounded-5 fw-bold px-4 py-2'>Add Property <RiArrowRightUpLine /></a>
                                </p>
                            </li>
                        </ul>
                    </div>
                    {/* fa-user */}
                    <span className=" d-lg-none d-block" style={{ fontSize: "36px" }} onClick={sidebar}>
                        <a className='text-dark' href="/register"><FaRegUserCircle /></a>
                    </span>
                </div>
                {/* Menu Button */}
                <span className="newnav-link d-lg-block d-none" style={{ fontSize: "36px" }} onClick={sidebar}>
                    <CiMenuFries />
                </span>
            </nav>
            <div className={`overlay ${sidebarOpen === "open" ? "active" : ""}`} id="overlay" onClick={sidebarclose}></div>
            <div className={`newsidebar sidebar ${sidebarOpen}`} id="sidebar" style={{ zIndex: 105 }}>
                <div className="d-flex justify-content-between pt-4 px-5">
                    <h4 style={{ textWrap: 'nowrap' }}>Welcome to RealEstate</h4>
                    <h3 onClick={sidebarclose}>
                        <IoCloseCircleOutline />
                    </h3>
                </div>
                <hr />
                <ul className='nav flex-column mt-5'>
                    <li className='nav-itme'><a className="nav-link" href="/">
                        Apartments</a></li>
                    <li className='nav-itme'><a className="nav-link" href="/">Bungalow</a></li>
                    <li className='nav-itme'><a className="nav-link" href="/">Houses</a></li>
                    <li className='nav-itme'><a className="nav-link" href="/">Loft</a></li>
                    <li className='nav-itme'><a className="nav-link" href="/">Office</a></li>
                    <li className='nav-itme'><a className="nav-link" href="/">Townhome</a></li>
                    <li className='nav-itme'><a className="nav-link" href="/">Villa</a></li>
                    <li className='nav-itme'><a className="nav-link" href="/">Total Free Customer Care</a></li>
                </ul>
            </div>
        </Fragment >
    )
}

export default Header
