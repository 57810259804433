import React, { Fragment } from 'react'
import ladypic from '../../Assets/images/lady.jpg'
import { IoCall } from 'react-icons/io5'
import { RiWhatsappLine } from 'react-icons/ri'
import { FaFacebook, FaInstagram, FaLinkedin } from 'react-icons/fa'
import { FaSquareXTwitter } from 'react-icons/fa6'
import { GoArrowUpRight } from 'react-icons/go'

function GetMoreInformation() {
    return (
        <Fragment>
            <div className="container-fluid get-more-information-full-container bg-light mt-4 rounded-4">
                <div className="get-more-information-container">
                    <h2>Get More Information</h2>
                    <div className="get-more-content">
                        <div className="d-flex justify-content-evenly">
                            <img src={ladypic} alt="" />
                            <div>
                                <h6>Arlene McCoy</h6>
                                <p>
                                    <span> <IoCall size={20} /> (920) 012-3421 | <IoCall size={20} /> (920) 012-4390  | <RiWhatsappLine size={20} /> WhatsApp </span>
                                </p>
                                <p className='d-flex justify-content-start gap-4'>
                                    <FaFacebook /><FaLinkedin /><FaSquareXTwitter /><FaInstagram />
                                </p>
                            </div>
                        </div>
                        <hr />
                        <div class="continer">
                            <div class="d-lg-flex justify-content-between gap-3">
                                <div class="form-group w-100 mt-3">
                                    <label for="total-amount">Name</label>
                                    <input class="form-control" style={{ height: "60px" }} type="text" id="total-amount" placeholder="Rohit" />
                                </div>
                                <div class="form-group w-100 mt-3">
                                    <label for="down-payment">Mobile</label>
                                    <input class="form-control" style={{ height: "60px" }} type="text" id="down-payment" placeholder="Enter Your Phone number" />
                                </div>
                            </div>
                            <div class="d-lg-flex justify-content-between gap-3">
                                <div class="form-group w-100 mt-3">
                                    <label for="interest-rate">Email</label>
                                    <input class="form-control" style={{ height: "60px" }} type="email" id="interest-rate" placeholder="Your Email" />
                                </div>
                                <div class="form-group w-100 mt-3">
                                    <label for="loan-terms">I'm a
                                    </label>
                                    <input class="form-control" style={{ height: "60px" }} type="text" id="loan-terms" placeholder="Engineer" />
                                </div>
                            </div>
                            <div class="d-lg-flex justify-content-between gap-3">
                                <div class="form-group w-100 mt-3">
                                    <label for="property-tax">Message</label>
                                    <textarea class="form-control" rows={5} placeholder="Write Your Words" ></textarea>
                                </div>
                            </div>
                            <div className="container mt-3 text-center">
                                <button class="submit-btn"><span>Calculate</span>    <GoArrowUpRight /></button>
                            </div>
                        </div>


                    </div>
                </div>

            </div>
        </Fragment>
    )
}

export default GetMoreInformation
