import React from 'react'
import { Fragment } from 'react'
import Header from '../Common/Header.js';
import HeaderProperty from './HeaderProperty.js';
import GalleryProperty from './GalleryProperty.js';
import OverviewPropty from './OverviewPropty.js';
import Footer from '../Common/Footer.js';


function HomeProperty() {
    return (
        <Fragment>
            <div className="bg-dark" style={{ height: "110px" }}>
                <Header />
            </div>
            <div className='container-fluid' style={{ background: "#c6c3c3ca" }}>

                <HeaderProperty />
                <GalleryProperty />
                <OverviewPropty />
                
            </div>
            <Footer />
        </Fragment>
    )
}

export default HomeProperty
