import React, { Fragment, useEffect, useRef, useState } from 'react';
import 'nouislider/dist/nouislider.css';
import noUiSlider from "nouislider";
import { CiHeart, CiSearch } from 'react-icons/ci'
import { GrFormSubtract } from 'react-icons/gr';
import { BsSliders } from 'react-icons/bs';
import Dropdown from 'react-bootstrap/Dropdown';
import home1 from '../../Assets/images/Listing/home1.jpg';
import home2 from '../../Assets/images/Listing/home2.jpg';
import home3 from '../../Assets/images/Listing/home3.jpg';
import home4 from '../../Assets/images/Listing/home4.jpg';
import home5 from '../../Assets/images/Listing/home5.jpg';
import home6 from '../../Assets/images/Listing/home6.jpg';
import home7 from '../../Assets/images/Listing/home7.jpg';
import home8 from '../../Assets/images/Listing/home8.jpg';
import home9 from '../../Assets/images/Listing/home9.jpg';
import home10 from '../../Assets/images/Listing/home10.jpg';
import { IoBedOutline } from 'react-icons/io5';
import { FaShower } from 'react-icons/fa';
import { PiResizeFill } from 'react-icons/pi';
import { BiLike } from 'react-icons/bi';
import { MdOutlineLibraryAddCheck } from 'react-icons/md';
import { useParams } from 'react-router-dom';
const countries = [
    "United States",
    "Canada",
    "Mexico",
    "Brazil",
    "Argentina",
    "United Kingdom",
    "Germany",
    "France",
    "Italy",
    "Spain",
    "Australia",
    "India",
    "China",
    "Japan",
    "South Korea",
    "South Africa",
    "Nigeria",
    "Russia",
    "Saudi Arabia",
    "Turkey"
];

const other_features = [
    {
        name: "Attic"
    },
    {
        name: "Basketball court"
    },
    {
        name: "Air Conditioning"
    },
    {
        name: "Lawn"
    },
    {
        name: "TV Cable"
    },
    {
        name: "Dryer"
    }
];
const other_features2 = [
    {
        name: "Outdoor Shower"
    },
    {
        name: "Washer"
    },
    {
        name: "Lake view"
    },
    {
        name: "Wine cellar"
    },
    {
        name: "Front yard"
    },
    {
        name: "Refrigerator"
    },
]
const listhome = [
    {
        "id": 1,
        "img": `${home1}`,
        "imgtitle": "image1",
        "title": "Equestrian Family Home",
        "text1": "5 Beds",
        "text2": "4 Bath",
        "text3": "4,000 SqFt",
    },
    {
        "id": 2,
        "img": `${home2}`,
        "imgtitle": "image2",
        "title": "Modern Urban Home",
        "text1": "3 Beds",
        "text2": "2 Bath",
        "text3": "2,500 SqFt",
    },
    {
        "id": 3,
        "img": `${home3}`,
        "imgtitle": "image3",
        "title": "Luxury Villa",
        "text1": "6 Beds",
        "text2": "5 Bath",
        "text3": "5,200 SqFt",
    },
    {
        "id": 4,
        "img": `${home4}`,
        "imgtitle": "image4",
        "title": "Contemporary Cottage",
        "text1": "2 Beds",
        "text2": "1 Bath",
        "text3": "1,200 SqFt",
    },
    {
        "id": 5,
        "img": `${home5}`,
        "imgtitle": "image5",
        "title": "Beachfront Bungalow",
        "text1": "4 Beds",
        "text2": "3 Bath",
        "text3": "3,500 SqFt",
    },
    {
        "id": 6,
        "img": `${home6}`,
        "imgtitle": "image6",
        "title": "Country Estate",
        "text1": "7 Beds",
        "text2": "6 Bath",
        "text3": "6,000 SqFt",
    },
    {
        "id": 7,
        "img": `${home7}`,
        "imgtitle": "image7",
        "title": "Downtown Loft",
        "text1": "2 Beds",
        "text2": "2 Bath",
        "text3": "1,800 SqFt",
    },
    {
        "id": 8,
        "img": `${home8}`,
        "imgtitle": "image8",
        "title": "Suburban Dream Home",
        "text1": "5 Beds",
        "text2": "4 Bath",
        "text3": "4,300 SqFt",
    },
    {
        "id": 9,
        "img": `${home9}`,
        "imgtitle": "image9",
        "title": "Rustic Cabin",
        "text1": "3 Beds",
        "text2": "2 Bath",
        "text3": "2,100 SqFt",
    },
    {
        "id": 10,
        "img": `${home10}`,
        "imgtitle": "image10",
        "title": "Penthouse Suite",
        "text1": "4 Beds",
        "text2": "4 Bath",
        "text3": "4,800 SqFt",
    },
    {
        "id": 11,
        "img": `${home1}`,
        "imgtitle": "image11",
        "title": "Lakefront Lodge",
        "text1": "6 Beds",
        "text2": "5 Bath",
        "text3": "5,700 SqFt",
    },
    {
        "id": 12,
        "img": `${home2}`,
        "imgtitle": "image12",
        "title": "Urban Townhouse",
        "text1": "3 Beds",
        "text2": "3 Bath",
        "text3": "2,900 SqFt",
    },
    {
        "id": 13,
        "img": `${home3}`,
        "imgtitle": "image13",
        "title": "Mountain Chalet",
        "text1": "4 Beds",
        "text2": "3 Bath",
        "text3": "3,600 SqFt",
    },
    {
        "id": 14,
        "img": `${home4}`,
        "imgtitle": "image14",
        "title": "Colonial Mansion",
        "text1": "8 Beds",
        "text2": "6 Bath",
        "text3": "7,500 SqFt",
    },
    {
        "id": 15,
        "img": `${home5}`,
        "imgtitle": "image15",
        "title": "Victorian House",
        "text1": "5 Beds",
        "text2": "4 Bath",
        "text3": "4,200 SqFt",
    },
    {
        "id": 16,
        "img": `${home6}`,
        "imgtitle": "image16",
        "title": "Mediterranean Villa",
        "text1": "6 Beds",
        "text2": "5 Bath",
        "text3": "5,100 SqFt",
    },
    {
        "id": 17,
        "img": `${home7}`,
        "imgtitle": "image17",
        "title": "Desert Ranch",
        "text1": "4 Beds",
        "text2": "3 Bath",
        "text3": "3,700 SqFt",
    },
    {
        "id": 18,
        "img": `${home8}`,
        "imgtitle": "image18",
        "title": "Contemporary Villa",
        "text1": "5 Beds",
        "text2": "4 Bath",
        "text3": "4,500 SqFt",
    },
    {
        "id": 19,
        "img": `${home9}`,
        "imgtitle": "image19",
        "title": "Hilltop Retreat",
        "text1": "3 Beds",
        "text2": "3 Bath",
        "text3": "3,200 SqFt",
    },
    {
        "id": 20,
        "img": `${home10}`,
        "imgtitle": "image20",
        "title": "Seaside Escape",
        "text1": "4 Beds",
        "text2": "3 Bath",
        "text3": "4,000 SqFt",
    },
    {
        "id": 21,
        "img": `${home1}`,
        "imgtitle": "image21",
        "title": "Countryside Manor",
        "text1": "6 Beds",
        "text2": "5 Bath",
        "text3": "6,200 SqFt",
    },
    {
        "id": 22,
        "img": `${home2}`,
        "imgtitle": "image22",
        "title": "Cliffside Residence",
        "text1": "5 Beds",
        "text2": "4 Bath",
        "text3": "5,000 SqFt",
    },
    {
        "id": 23,
        "img": `${home3}`,
        "imgtitle": "image23",
        "title": "Historic Estate",
        "text1": "7 Beds",
        "text2": "6 Bath",
        "text3": "7,800 SqFt",
    },
    {
        "id": 24,
        "img": `${home4}`,
        "imgtitle": "image24",
        "title": "City Penthouse",
        "text1": "3 Beds",
        "text2": "3 Bath",
        "text3": "3,300 SqFt",
    }
];


function HeaderList() {
    const { id } = useParams();
    const homes = `col-lg-${id}`;

    const [isOpen, setIsOpen] = useState('');
    const sliderRef = useRef(null);
    const [minValue, setMinValue] = useState(3467);
    const [maxValue, setMaxValue] = useState(100000);

    useEffect(() => {
        const slider = sliderRef.current;
        if (slider) {
            noUiSlider.create(slider, {
                start: [minValue, maxValue],
                connect: true,
                behaviour: 'tap-drag',
                range: {
                    min: 0,
                    max: 100000,
                },
                format: {
                    to: (value) => Math.round(value),
                    from: (value) => Number(value),
                },
            });

            // Listen to slider updates
            sliderRef.current.noUiSlider.on("update", (values) => {
                setMinValue(values[0]);
                setMaxValue(values[1]);
            });
        }
        return () => {
            if (slider && slider.noUiSlider) {
                slider.noUiSlider.destroy();
            }
        };
    }, [minValue, maxValue]);



    return (
        <Fragment>
            <div className="container-fluid full-container-list">
                <div className="ms-auto me-auto container-list">
                    {/* Header */}
                    <h1 className='pt-5'>New York Homes for Sale</h1>
                    <div className="d-flex justify-content-between">
                        <p>
                            <a href="/">Home</a> /  <a href="/">For Rent</a>
                        </p>
                        <p className='d-block d-lg-none' onClick={() => {
                            setIsOpen('isActive');
                        }}>
                            <span><BsSliders /></span>Filter<span></span>
                        </p>
                    </div>



                    {/* content-container */}
                    <div className="content-container">
                        <div className="d-lg-flex justify-content-between flex-row-reverse gap-2">
                            {/* First row */}
                            <div className="list-sidebar-style1" style={{ width: '100%' }}>
                                <div className='d-lg-flex justify-content-between'>
                                    <p className='pt-lg-2 ps-lg-4 text-lg-start text-center'>
                                        Showing 1-8 of 25 results
                                    </p>
                                    <p className='d-flex justify-content-end gap-2 pe-4'>
                                        <span className='btn border-0'>Sort by</span>
                                        <span >
                                            <Dropdown>
                                                <Dropdown.Toggle variant="" id="dropdown-basic">
                                                    Newest
                                                </Dropdown.Toggle>

                                                <Dropdown.Menu>
                                                    <Dropdown.Item href="/">Best Seller</Dropdown.Item>
                                                    <Dropdown.Item href="/">Best Match</Dropdown.Item>
                                                    <Dropdown.Item href="/">Price Low</Dropdown.Item>
                                                    <Dropdown.Item href="/">Price High</Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </span>
                                        <span className='btn btn-transparent rounded-0 border-dark border-top-0 border-left-1 border-right-1 border-bottom-0'>
                                            Grid
                                        </span>
                                        <span className='btn border-0'>
                                            List
                                        </span>
                                    </p>
                                </div>

                                <div className="container listing-card-container">
                                    <div className="row g-4">
                                        {listhome.map((d, i) => {
                                            return (
                                                <div key={++i} className={`${homes}`}>
                                                    <div className="card">
                                                        <img src={`${d.img}`} className="card-img" alt="..." />
                                                        <div className="card-header">
                                                            {`${d.imgtitle}`}
                                                        </div>
                                                        <div className="card-body">
                                                            <div className="card-title">
                                                                {d.title}
                                                            </div>
                                                            <div className="card-text">
                                                                <div className='list-group list-group-horizontal d-flex justify-content-center'>
                                                                    <li className='list-group-item'><IoBedOutline /> {d.text1}</li>

                                                                    <li className='list-group-item'><FaShower /> {d.text2}</li>

                                                                    <li className='list-group-item'><PiResizeFill /> {d.text3}</li>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <hr />
                                                        <div className="d-flex justify-content-evenly gap-5">
                                                            <p>For Rent</p>
                                                            <p><CiHeart /> <BiLike /> <MdOutlineLibraryAddCheck /></p>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })};
                                    </div>
                                    <div className="container d-flex justify-content-center">
                                        <nav aria-label="Page navigation example">
                                            <ul class="pagination">
                                                <li class="page-item">
                                                    <a class="page-link" href="/" aria-label="Previous">
                                                        <span aria-hidden="true">&laquo;</span>
                                                    </a>
                                                </li>
                                                <li class="page-item"><a class="page-link" href="/">1</a></li>
                                                <li class="page-item"><a class="page-link" href="/">2</a></li>
                                                <li class="page-item"><a class="page-link" href="/">3</a></li>
                                                <li class="page-item">
                                                    <a class="page-link" href="/" aria-label="Next">
                                                        <span aria-hidden="true">&raquo;</span>
                                                    </a>
                                                </li>
                                            </ul>
                                        </nav>
                                    </div>
                                </div>

                            </div>



                            {/* second row */}
                            <div className={`${isOpen === 'isActive' ? "list-overlay" : ''}`} onClick={() => {
                                setIsOpen('');
                            }}></div>
                            <div className={`${isOpen} list-sidebar-style animate__animated animate__fadeInLeft`} style={{ maxWidth: '400px' }}>
                                <div className="step1">
                                    <div className="d-flex justify-content-between">
                                        <h3>Find your home</h3>
                                        <h3 className='d-block d-lg-none' onClick={() => {
                                            setIsOpen('');
                                        }}>X</h3>
                                    </div>
                                    <div className="input-group">
                                        <label className='input-group-text' htmlFor=""><CiSearch /></label>
                                        <input className='form-control' type="text" placeholder='What are you looking for?' />
                                    </div>
                                </div>
                                <div className="step2">
                                    <h3>Listing Status</h3>
                                    <div className="form-check">
                                        <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" checked />
                                        <label className="form-check-label" for="flexRadioDefault1">All</label>
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" />
                                        <label className="form-check-label" for="flexRadioDefault2">Buy</label>
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault3" />
                                        <label className="form-check-label" for="flexRadioDefault3">Rent</label>
                                    </div>
                                </div>
                                <div className="step3">
                                    <h3>Property Type</h3>
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                        <label className="form-check-label" for="flexCheckDefault">
                                            All
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" value="" id="flexCheckChecked" />
                                        <label className="form-check-label" for="flexCheckChecked">
                                            Houses
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" value="" id="flexCheckChecked2" />
                                        <label className="form-check-label" for="flexCheckChecked2">
                                            Apartments
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" value="" id="flexCheckChecked3" />
                                        <label className="form-check-label" for="flexCheckChecked3">
                                            Office
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" value="" id="flexCheckChecked4" />
                                        <label className="form-check-label" for="flexCheckChecked4">
                                            Villa
                                        </label>
                                    </div>
                                </div>
                                <div className="step4">
                                    <h3>
                                        Price Range
                                    </h3>
                                    <div className="form-check">
                                        <div id="slider-range" ref={sliderRef}></div>
                                        <div className="d-flex align-items-center mt-3">
                                            <span id="slider-range-value">${minValue}</span>
                                            <span><GrFormSubtract /></span>
                                            <span id="slider-range-value">${maxValue}</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="step5">
                                    <h3>Bedrooms</h3>
                                    <div className="input-group-text form-check">
                                        <lable className="input-group w-25">Any</lable>
                                        <button className='btn form-control'>1+</button>
                                        <button className='btn form-control'>2+</button>
                                        <button className='btn form-control'>3+</button>
                                        <button className='btn form-control'>4+</button>
                                        <button className='btn form-control'>5+</button>
                                    </div>
                                    <h3>Bathrooms</h3>
                                    <div className="input-group-text form-check">
                                        <lable className="input-group w-25">Any</lable>
                                        <button className='btn form-control'>1+</button>
                                        <button className='btn form-control'>2+</button>
                                        <button className='btn form-control'>3+</button>
                                        <button className='btn form-control'>4+</button>
                                        <button className='btn form-control'>5+</button>
                                    </div>
                                </div>
                                <div className="step6">
                                    <h3>
                                        Location
                                    </h3>
                                    <div className="form-check">
                                        <select className="form-select" size="4" aria-label="size 3 select example">
                                            <option selected disabled>All Cities</option>
                                            {countries.map((d, i) => {
                                                return <option key={i++}>{d}</option>
                                            })}
                                        </select>
                                    </div>
                                </div>
                                <div className="step7">
                                    <h3>Square Feet</h3>
                                    <div className="form-check d-flex">
                                        <input className='form-control' type="number" placeholder='200' />
                                        <span className='pt-2'><GrFormSubtract /></span>
                                        <input className='form-control' type="number" placeholder='200' />
                                    </div>
                                </div>
                                <div className="step8">
                                    <h3>Year Built</h3>
                                    <div className="form-check d-flex">
                                        <input className='form-control' type="number" placeholder='2018' />
                                        <span className='pt-2'><GrFormSubtract /></span>
                                        <input className='form-control' type="number" placeholder='2023' />
                                    </div>
                                </div>
                                <div className="step9">
                                    <div className="form-control">
                                        <p>
                                            <a className="btn" data-bs-toggle="collapse" href="#collapseExample" role="button" aria-expanded="false" aria-controls="collapseExample">
                                                <span><BsSliders /></span> <span>Other Features</span>
                                            </a>
                                        </p>
                                        <div className="collapse" id="collapseExample">
                                            <div className="card card-body">
                                                <div className="row g-3">
                                                    <div className="col-lg-6">
                                                        {other_features.map((d, i) => {
                                                            return (
                                                                <div key={++i} className="form-check">
                                                                    <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                                                    <label className="form-check-label" for="flexCheckDefault">
                                                                        {d.name}
                                                                    </label>
                                                                </div>
                                                            )
                                                        })}
                                                    </div>
                                                    <div className="col-lg-6">
                                                        {other_features2.map((d, i) => {
                                                            return (
                                                                <div key={++i} className="form-check">
                                                                    <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                                                    <label className="form-check-label" for="flexCheckDefault">
                                                                        {d.name}
                                                                    </label>
                                                                </div>
                                                            )
                                                        })}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <button className='form-control btn btn-dark btn-lg mt-3'>Submit</button>
                                    </div>
                                </div>

                            </div>

                        </div>

                    </div>
                </div>
            </div>
            {/* Pagination */}
        </Fragment>
    )
}

export default HeaderList
