import React, { useState } from 'react';
import axios from 'axios';
import Live_Base_url from '../../Common/Live_Base_Url';
import { Slide, ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const LocationDetails = ({ setActiveTab }) => {
    const [formData, setFormData] = useState({
        city: '',
        locality: '',
        sub_locality: '',
        project: '',
        address: '',
    });

    // Handle input changes
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleSubmit = async () => {
        try {
            const token = localStorage.getItem('Authorization');
            const Product_id = localStorage.getItem('Product_id');

            const response = await axios.post(`${Live_Base_url}/properties/${Product_id}/location`, formData, {
                headers: {
                    'Authorization': `${token}`,
                    'Content-Type': 'application/json',
                },
            });
            console.log('Response:', response?.data);
            if (response?.data?.success) {
                toast.success(response?.data?.message, {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    transition: Slide,
                });
            }else{
                toast.error(response?.data?.message, {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    transition: Slide,
                });
            }
        } catch (error) {
            console.error('Error submitting form:', error);
        }
    };

    return (
        <div className="location-details-container">
            <p className='step2'>Step 2</p>
            <h4 className='whatkindh4'>Where Is Your Property Located?</h4>
            <p className='whatkindp text-secondary'>An accurate location helps you connect with the right buyers</p>

            <div className="form-floating mb-3">
                <input
                    type="text"
                    className="form-control"
                    id="floatingInput"
                    placeholder="Your City"
                    name="city"
                    value={formData.city}
                    onChange={handleChange}
                />
                <label htmlFor="floatingInput">City</label>
            </div>

            <div className="form-floating mb-3">
                <input
                    type="text"
                    className="form-control"
                    id="floatinglocality"
                    placeholder="Locality"
                    name="locality"
                    value={formData.locality}
                    onChange={handleChange}
                />
                <label htmlFor="floatinglocality">Locality</label>
            </div>

            <div className="form-floating mb-3">
                <input
                    type="text"
                    className="form-control"
                    id="floatingsublocal"
                    placeholder="SubLocality"
                    name="sub_locality"
                    value={formData.sub_locality}
                    onChange={handleChange}
                />
                <label htmlFor="floatingsublocal">Sublocality</label>
            </div>

            <div className="form-floating mb-3">
                <input
                    type="text"
                    className="form-control"
                    id="floatingProject"
                    placeholder="Locality"
                    name="project"
                    value={formData.project}
                    onChange={handleChange}
                />
                <label htmlFor="floatingProject">Project (optional)</label>
            </div>

            <div className="form-floating mb-3">
                <input
                    type="text"
                    className="form-control"
                    id="floatingAddress"
                    placeholder="Locality"
                    name="address"
                    value={formData.address}
                    onChange={handleChange}
                />
                <label htmlFor="floatingAddress">Address (optional)</label>
            </div>

            <div className='basic-btn d-flex gap-3'>
                <button className='submit-btn' onClick={() => setActiveTab('Basic-Details')}>Back</button>
                <button className='submit-btn final-submit' onClick={handleSubmit}>Save</button>
                <button className='submit-btn' onClick={() => setActiveTab('Property-Profile')}>Next</button>
            </div>
            <ToastContainer />
        </div>
    );
};

export default LocationDetails;
