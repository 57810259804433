import React, { Fragment } from 'react'
import { BiCycling } from 'react-icons/bi'
import { BsFillTruckFrontFill } from 'react-icons/bs'
import { FaPersonWalking } from 'react-icons/fa6'

function WalkScore() {
    return (
        <Fragment>
            <div className="container-fluid walkscore-full-container  bg-light rounded-4  mt-4">
                <div class="walkscore">
                    <h2>Walkscore</h2>
                    <p>10425 Tabor St Los Angeles CA 90034 USA</p>

                    <div class="score-item">
                        <div class="score-icon">
                            <FaPersonWalking />
                        </div>
                        <div class="score-details">
                            <h3>Walk Score</h3>
                            <p>57 / 100 (Somewhat Walkable)</p>
                        </div>
                    </div>

                    <div class="score-item">
                        <div class="score-icon">
                            <BsFillTruckFrontFill />
                        </div>
                        <div class="score-details">
                            <h3>Transit Score</h3>
                            <p>27 / 100 (Some Transit)</p>
                        </div>
                    </div>

                    <div class="score-item">
                        <div class="score-icon">
                            <BiCycling />
                        </div>
                        <div class="score-details">
                            <h3>Bike Score</h3>
                            <p>45 / 100 (Somewhat Bikeable)</p>
                        </div>
                    </div>
                </div>

            </div>
        </Fragment>
    )
}

export default WalkScore
