import React, { useState } from 'react';
import axios from 'axios';
import { GrSubtractCircle } from 'react-icons/gr';
import { IoMdAddCircleOutline } from 'react-icons/io';
import Live_Base_url from '../../Common/Live_Base_Url';
import { Slide, toast, ToastContainer } from 'react-toastify';

const itemsList1 = [
    { key: 'sofa', name: 'Sofa' },
    { key: 'lights', name: 'Lights' },
    { key: 'geyser', name: 'Geyser' },
    { key: 'wardrobe', name: 'Wardrobe' },
    { key: 'refrigerator', name: 'Refrigerator' },
    { key: 'water_purifier', name: 'Water Purifier' },
    { key: 'washing_machine', name: 'Washing Machine' },
];

const itemsList2 = [
    { key: 'ac', name: 'AC' },
    { key: 'tv', name: 'TV' },
    { key: 'bed', name: 'Bed' },
    { key: 'fans', name: 'Fans' },
    { key: 'stove', name: 'Stove' },
    { key: 'microwave', name: 'Microwave' },
];


const PropertyProfile = ({ setActiveTab }) => {
    const [selectedRooms, setSelectedRooms] = useState([]);
    const [furnish, setFurnish] = useState('');
    const [bedrooms, setBedrooms] = useState();
    const [washrooms, setWashrooms] = useState();
    const [balconies, setBalconies] = useState('');
    const [carpetArea, setCarpetArea] = useState('');
    const [carpetAreaUnit, setCarpetAreaUnit] = useState('Sq. ft.');
    const [builtUpArea, setBuiltUpArea] = useState('');
    const [builtUpUnit, setBuiltUpUnit] = useState('Sq. ft.');
    const [furnishings, setFurnishings] = useState({});


    const toggleRoomSelection = (room) => {
        setSelectedRooms((prevSelectedRooms) => {
            if (prevSelectedRooms.includes(room)) {
                return prevSelectedRooms.filter((r) => r !== room); // Remove room
            } else {
                return [...prevSelectedRooms, room]; // Add room
            }
        });
    };

    const renderFurnished = () => {
        switch (furnish) {
            case 'furnished':
                return (
                    <div className="furnishing-render-element">
                        <p className='atleast'>At least three furnishings are mandatory for furnished</p>
                        <div className="furnished-element">
                            <ul>{renderItems(itemsList1)}</ul>
                            <ul>{renderItems(itemsList2)}</ul>
                        </div>
                    </div>
                );
            default:
                break;
        }
    }

    const updateFurnishingQuantity = (itemKey, operation) => {
        setFurnishings((prevFurnishings) => {
            const currentQuantity = prevFurnishings[itemKey] || 0;

            const newQuantity = operation === 'increment'
                ? currentQuantity + 1
                : Math.max(currentQuantity - 1, 0);

            return {
                ...prevFurnishings,
                [itemKey]: newQuantity,
            };
        });
    };

    const renderItems = (items) =>
        items.map((item) => {
            const quantity = furnishings[item.key] || 0;
            return (
                <li key={item.key}>
                    <span>{quantity}</span>
                    <span id="circle" onClick={() => updateFurnishingQuantity(item.key, 'increment')}>
                        <IoMdAddCircleOutline />
                    </span>
                    <span id="circle" onClick={() => updateFurnishingQuantity(item.key, 'decrement')}>
                        <GrSubtractCircle />
                    </span>
                    {item.name}
                </li>
            );
        });
    const handleSubmit = async () => {
        const apiData = {
            bedrooms,
            washrooms,
            balconies,
            other_rooms: selectedRooms.join(', '),
            furnishing: furnish,
            carpet_area: carpetArea,
            carpet_area_unit: carpetAreaUnit,
            built_up_area: builtUpArea,
            built_up_unit: builtUpUnit,
        };
        try {

            const token = localStorage.getItem('Authorization');
            const Product_id = localStorage.getItem('Product_id');

            const response = await axios.post(`${Live_Base_url}/properties/${Product_id}/property-profile`, apiData, {
                headers: {
                    'Authorization': `${token}`,
                    'Content-Type': 'application/json',
                },
            });
            console.log('Response:', response.data);
            if (response?.data?.success) {
                toast.success(response?.data?.message, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    transition: Slide,
                });


            } else {
                toast.error(response?.data?.message, {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    transition: Slide,
                });
            }
        } catch (error) {
            console.error('Error submitting property details:', error);
        }
    };

    return (
        <div className="property-profile-container">
            <p className='step3'>Step 3</p>
            <h4 className='whatkindh4'>Tell us about your property</h4>
            <div className='property-profile-content'>
                <h5>Add Room Details</h5>
                <input
                    type="number"
                    className="form-control"
                    placeholder="Enter total number of rooms"
                    value={bedrooms}
                    onChange={(e) => setBedrooms(e.target.value)}
                    min="0"
                    required
                />

                <span className='no-of-washroom'>
                    <h6>No. of Washrooms</h6>
                    <span className='d-flex gap-1 flex-wrap'>
                        <button className={`btn ${washrooms === 0 ? "active" : " "}`} onClick={() => setWashrooms(0)}>None</button>
                        <button className={`btn ${washrooms === 'Shared' ? "active" : " "}`} onClick={() => setWashrooms('Shared')}>Shared</button>
                        <button className={`btn ${washrooms === 1 ? "active" : " "}`} onClick={() => setWashrooms(1)}>1</button>
                        <button className={`btn ${washrooms === 2 ? "active" : " "}`} onClick={() => setWashrooms(2)}>2</button>
                        <button className={`btn ${washrooms === 3 ? "active" : " "}`} onClick={() => setWashrooms(3)}>3</button>
                        <button className={`btn ${washrooms === 4 ? "active" : " "}`} onClick={() => setWashrooms(4)}>4</button>
                    </span>
                </span>
                <span className='no-of-balconies'>
                    <h6>No. of Balconies</h6>
                    <span className='d-flex justify-content-start gap-1 flex-wrap'>
                        <button className={`btn ${balconies === 0 ? "active" : " "}`} onClick={() => setBalconies(0)}>0</button>
                        <button className={`btn ${balconies === 1 ? "active" : " "}`} onClick={() => setBalconies(1)}>1</button>
                        <button className={`btn ${balconies === 2 ? "active" : " "}`} onClick={() => setBalconies(2)}>2</button>
                        <button className={`btn ${balconies === 3 ? "active" : " "}`} onClick={() => setBalconies(3)}>3</button>
                        <button className={`btn ${balconies === 4 ? "active" : " "}`} onClick={() => setBalconies(4)}>4</button>
                        <button className={`btn ${balconies === 5 ? "active" : " "}`} onClick={() => setBalconies(5)}>5</button>
                        <button className={`btn ${balconies === 6 ? "active" : " "}`} onClick={() => setBalconies(6)}>6</button>
                    </span>
                </span>

                <div className="add-area-details">
                    <h5 className='pt-4'>Add Area Details</h5>
                    <div className="input-group mb-3">
                        <input
                            type="text"
                            className="form-control"
                            placeholder='Plot Area'
                            value={carpetArea}
                            onChange={(e) => setCarpetArea(e.target.value)}
                        />
                        <select
                            className="btn"
                            value={carpetAreaUnit}
                            onChange={(e) => setCarpetAreaUnit(e.target.value)}
                        >
                            <option value="Sq. ft.">Sq. ft.</option>
                            <option value="Sq. yards">Sq. yards</option>
                            <option value="Sq. m.">Sq. m.</option>
                            <option value="acres">acres</option>
                            <option value="marla">marla</option>
                            <option value="cent">cent</option>
                        </select>
                    </div>
                    <div className="input-group mb-3">
                        <input
                            type="text"
                            className="form-control"
                            placeholder='Carpet Area'
                            value={builtUpArea}
                            onChange={(e) => setBuiltUpArea(e.target.value)}
                        />
                        <select
                            className="btn"
                            value={builtUpUnit}
                            onChange={(e) => setBuiltUpUnit(e.target.value)}
                        >
                            <option value="Sq. ft.">Sq. ft.</option>
                            <option value="Sq. yards">Sq. yards</option>
                            <option value="Sq. m.">Sq. m.</option>
                            <option value="acres">acres</option>
                            <option value="marla">marla</option>
                            <option value="cent">cent</option>
                        </select>
                    </div>
                </div>

                <div className="other-rooms">
                    <h6>Other rooms (optional)</h6>
                    <div className="btn-groups">
                        <button
                            className={`btn ${selectedRooms.includes('pooja') ? 'active' : ''}`}
                            onClick={() => toggleRoomSelection('pooja')}
                        >
                            Pooja Room
                        </button>

                        <button
                            className={`btn ${selectedRooms.includes('study') ? 'active' : ''}`}
                            onClick={() => toggleRoomSelection('study')}
                        >
                            Study Room
                        </button>

                        <button
                            className={`btn ${selectedRooms.includes('servent') ? 'active' : ''}`}
                            onClick={() => toggleRoomSelection('servent')}
                        >
                            Servent Room
                        </button>

                        <button
                            className={`btn ${selectedRooms.includes('guest') ? 'active' : ''}`}
                            onClick={() => toggleRoomSelection('guest')}
                        >
                            Guest Room
                        </button>
                    </div>
                </div>

                <div className="furnishing">
                    <h6>Furnishing</h6>
                    <div className="btn-groups">
                        <button
                            className={`btn ${furnish === 'furnished' ? 'active' : ''}`}
                            onClick={() => setFurnish('furnished')}
                        >
                            Furnished
                        </button>

                        <button
                            className={`btn ${furnish === 'semi-furnished' ? 'active' : ''}`}
                            onClick={() => setFurnish('semi-furnished')}
                        >
                            Semi-furnished
                        </button>

                        <button
                            className={`btn ${furnish === 'un-furnished' ? 'active' : ''}`}
                            onClick={() => setFurnish('un-furnished')}
                        >
                            Un-furnished
                        </button>
                    </div>
                    <div>
                        {renderFurnished()}
                    </div>
                </div>
            </div>

            <div className='basic-btn d-flex gap-3'>
                <button
                    className="submit-btn"
                    onClick={() => setActiveTab('Location-Details')}
                >
                    Back
                </button>
                <button className="submit-btn final-submit" onClick={() => handleSubmit()} >Save</button>
                <button className='submit-btn ' onClick={() => { setActiveTab('Videos&Photos'); }}>Next</button>
            </div>
            <ToastContainer/>
        </div>
    );
};

export default PropertyProfile;
