import React, { Fragment } from 'react'
import Header from '../Common/Header'
import HeroSection from './HeroSection'
import Explore from './Explore'
import HowHelp from './HowHelp'
import Discover from './Discover'
import ByCity from './ByCity'
import Selling from './Selling'
import SecondDiscover from './SecondDiscover'
import TestiMonials from './TestiMonials'
import Blog from './Blog'
import Company from './Company'
import Footer from '../Common/Footer'

function Home() {
    return (
        <Fragment>
            <Header />
            <HeroSection />
            <Explore />
            <HowHelp />
            <Discover />
            <ByCity />
            <Selling />
            <SecondDiscover />
            <TestiMonials />
            <Blog />
            <Company/>
            <Footer/>
        </Fragment>
    )
}

export default Home
