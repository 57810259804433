import React, { Fragment } from 'react'

function MortgageCalculator() {
    return (
        <Fragment>
            <div className="container-fluid mortgage-full-container bg-light rounded-4 mt-4">
                <div className="mortgage">
                    <h2>Mortgage Calculator</h2>

                    <div className="progress-bar">
                        <div className="progress w-50" ></div>
                    </div>

                    <div className="costs">
                        <div className="cost-item">
                            <span className='cost-item-item'>Principal and Interest</span>
                            <span>$2,412</span>
                        </div>
                        <div className="cost-item">
                            <span className='cost-item-item'>Homeowners' Insurance</span>
                            <span>$2,412</span>
                        </div>
                        <div className="cost-item">
                            <span className='cost-item-item'>Property Taxes</span>
                            <span>$2,412</span>
                        </div>
                    </div>
                    <div className="d-lg-flex justify-content-between">
                        <div className="form-group">
                            <label for="total-amount">Total Amount</label>
                            <input className='form-control' type="text" id="total-amount" placeholder="$250" />
                        </div>
                        <div className="form-group">
                            <label for="down-payment">Down Payment</label>
                            <input className='form-control' type="text" id="down-payment" placeholder="$2304" />
                        </div>
                    </div>
                    <div className="d-lg-flex justify-content-between">
                        <div className="form-group">
                            <label for="interest-rate">Interest Rate</label>
                            <input className='form-control' type="text" id="interest-rate" placeholder="3.5%" />
                        </div>
                        <div className="form-group">
                            <label for="loan-terms">Loan Terms (Years)</label>
                            <input className='form-control' type="text" id="loan-terms" placeholder="12" />
                        </div>
                    </div>
                    <div className="d-lg-flex justify-content-between">
                        <div className="form-group">
                            <label for="property-tax">Property Tax</label>
                            <input className='form-control' type="text" id="property-tax" placeholder="$1000" />
                        </div>

                        <div className="form-group">
                            <label for="home-insurance">Home Insurance</label>
                            <input className='form-control' type="text" id="home-insurance" placeholder="$1000" />
                        </div>
                    </div>
                    <div className="container text-center">
                        <button className="submit-btn">Calculate</button>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default MortgageCalculator
