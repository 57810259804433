import React, { Fragment, useState } from 'react'

function NearByProperty() {

    const [activeTab, setActiveTab] = useState('Education'); // Set initial tab

    const renderContent = () => {
        switch (activeTab) {
            case 'Education':
                return (

                    <div class="nearby-list animate__animated animate__fadeInLeft">
                        <div class="nearby-item">
                            <div class="rating-circle">
                                <span>4/10</span>
                            </div>
                            <div class="nearby-info">
                                <h3>Transportation 1</h3>
                                <p>Distance: 3.7 mi</p>
                            </div>
                            <div class="stars">
                                <span>⭐⭐⭐⭐⭐</span>
                            </div>
                        </div>
                        <div class="nearby-item">
                            <div class="rating-circle">
                                <span>5/10</span>
                            </div>
                            <div class="nearby-info">
                                <h3>Transportation 2</h3>
                                <p>Distance: 3.7 mi</p>
                            </div>
                            <div class="stars">
                                <span>⭐⭐⭐⭐⭐</span>
                            </div>
                        </div>
                        <div class="nearby-item">
                            <div class="rating-circle">
                                <span>5/10</span>
                            </div>
                            <div class="nearby-info">
                                <h3>Transportation 3</h3>
                                <p>Distance: 3.7 mi</p>
                            </div>
                            <div class="stars">
                                <span>⭐⭐⭐⭐⭐</span>
                            </div>
                        </div>
                    </div>

                );
            case 'Health & Medical':
                return (
                    <div class="nearby-list  animate__animated animate__fadeInRight">
                        <div class="nearby-item">
                            <div class="rating-circle">
                                <span>4/10</span>
                            </div>
                            <div class="nearby-info">
                                <h3>Transportation 1</h3>
                                <p>Distance: 3.7 mi</p>
                            </div>
                            <div class="stars">
                                <span>⭐⭐⭐⭐⭐</span>
                            </div>
                        </div>
                        <div class="nearby-item">
                            <div class="rating-circle">
                                <span>5/10</span>
                            </div>
                            <div class="nearby-info">
                                <h3>Transportation 2</h3>
                                <p>Distance: 3.7 mi</p>
                            </div>
                            <div class="stars">
                                <span>⭐⭐⭐⭐⭐</span>
                            </div>
                        </div>
                        <div class="nearby-item">
                            <div class="rating-circle">
                                <span>5/10</span>
                            </div>
                            <div class="nearby-info">
                                <h3>Transportation 3</h3>
                                <p>Distance: 3.7 mi</p>
                            </div>
                            <div class="stars">
                                <span>⭐⭐⭐⭐⭐</span>
                            </div>
                        </div>
                    </div>
                );
            case 'Transportation':
                return (
                    <div class="nearby-list  animate__animated animate__backInDown">
                        <div class="nearby-item">
                            <div class="rating-circle">
                                <span>4/10</span>
                            </div>
                            <div class="nearby-info">
                                <h3>Transportation 1</h3>
                                <p>Distance: 3.7 mi</p>
                            </div>
                            <div class="stars">
                                <span>⭐⭐⭐⭐⭐</span>
                            </div>
                        </div>
                        <div class="nearby-item">
                            <div class="rating-circle">
                                <span>5/10</span>
                            </div>
                            <div class="nearby-info">
                                <h3>Transportation 2</h3>
                                <p>Distance: 3.7 mi</p>
                            </div>
                            <div class="stars">
                                <span>⭐⭐⭐⭐⭐</span>
                            </div>
                        </div>
                        <div class="nearby-item">
                            <div class="rating-circle">
                                <span>5/10</span>
                            </div>
                            <div class="nearby-info">
                                <h3>Transportation 3</h3>
                                <p>Distance: 3.7 mi</p>
                            </div>
                            <div class="stars">
                                <span>⭐⭐⭐⭐⭐</span>
                            </div>
                        </div>
                    </div>
                );
            default:
                return null;
        }
    };
    return (
        <Fragment>
            <div className="container-fluid near-by-full-container bg-light rounded-4  mt-4">
                <div className="nearby-section">
                    <div className="headers">
                        <h2>What's Nearby?</h2>
                    </div>
                    <div className="tabs ">
                        <ul className='d-flex'>
                            <li
                                className={`tab-item  ${activeTab === 'Education' ? 'active' : ''}`}
                                onClick={() => setActiveTab('Education')}
                            >
                                Education
                            </li>
                            <li
                                className={`tab-item  ${activeTab === 'Health & Medical' ? 'active' : ''}`}
                                onClick={() => setActiveTab('Health & Medical')}
                            >
                                Health & Medical
                            </li>
                            <li
                                className={`tab-item  ${activeTab === 'Transportation' ? 'active' : ''}`}
                                onClick={() => setActiveTab('Transportation')}
                            >
                                Transportation
                            </li>
                        </ul>
                    </div>

                    {/* Dynamic content rendering based on active tab */}
                    {renderContent()}
                </div>
            </div>
        </Fragment>
    )
}

export default NearByProperty
