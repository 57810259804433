import React, { Fragment } from 'react'
import { AiOutlineHome } from 'react-icons/ai'
import { FaBed, FaShower } from 'react-icons/fa'
import { GiHomeGarage } from 'react-icons/gi'
import { GoArrowUpRight } from 'react-icons/go'
import { LuCopyPlus } from 'react-icons/lu'
import { SlCalender } from 'react-icons/sl'

import { IoCallOutline } from 'react-icons/io5'
import Dplogo from '../../Assets/images/dplogo.jpg';
import energyscale from '../../Assets/images/energy-class.png';
import FloorPlans from './FloorPlans';
import VideoProperty from './VideoProperty'
import VirtualTour from './VirtualTour'
import NearByProperty from './NearByProperty'
import WalkScore from './WalkScore'
import MortgageCalculator from './MortgageCalculator'
import PropertyView from './PropertyView'
import HomeValue from './HomeValue'
import GetMoreInformation from './GetMoreInformation'
import PropertyReview from './PropertyReview'
import LeaveAReview from './LeaveAReview'
import DiscoverFeatureProperty from './DicoverFeatureProperty'


function OverviewPropty() {
    return (
        <Fragment>
            <div className="overview-full-container container-fluid">
                <div className="overview-container container">
                    <div className="row g-3">
                        <div className="col-lg-8 rounded-3">
                            <div className="bg-light p-4 rounded-4 mb-4">
                                <h3>Overview</h3>
                                <div className="row g-4">
                                    <div className="col-lg-4 col-md-6">
                                        <div className="overview-box">
                                            <button className='btn border-1 border-dark btn-sm px-4 fs-3'><FaBed /></button>
                                            <p>
                                                Property Type
                                                <br />
                                                1
                                            </p>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-6">
                                        <div className="overview-box">
                                            <button className='btn border-1 border-dark btn-sm px-4 fs-3'><FaShower /> </button>
                                            <p>
                                                Property Type
                                                <br />
                                                1
                                            </p>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-6">
                                        <div className="overview-box">
                                            <button className='btn border-1 border-dark btn-sm px-4 fs-3'><SlCalender /></button>
                                            <p>
                                                Property Type
                                                <br />
                                                1
                                            </p>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-6">
                                        <div className="overview-box">
                                            <button className='btn border-1 border-dark btn-sm px-4 fs-3'><GiHomeGarage /></button>
                                            <p>
                                                Property Type
                                                <br />
                                                1
                                            </p>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-6">
                                        <div className="overview-box">
                                            <button className='btn border-1 border-dark btn-sm px-4 fs-3'><LuCopyPlus /></button>
                                            <p>
                                                Property Type
                                                <br />
                                                1
                                            </p>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-6">
                                        <div className="overview-box">
                                            <button className='btn border-1 border-dark btn-sm px-4 fs-3'><AiOutlineHome /></button>
                                            <p>
                                                Property Type
                                                <br />
                                                1
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="bg-light p-4 rounded-4 mb-4">
                                <h4>Property Description</h4>
                                <p>This 3-bed with a loft, 2-bath home in the gated community of The Hideout has it all. From the open floor plan to the abundance of light from the windows, this home is perfect for entertaining. The living room and dining room have vaulted ceilings and a beautiful fireplace. You will love spending time on the deck taking in the beautiful views. In the kitchen, you'll find stainless steel appliances and a tile backsplash, as well as a breakfast bar.</p>
                                <a className='text-dark' href="/">show more</a>
                                <h4 className='mt-4'>Property Details</h4>
                                <div className='row gx-5 '>
                                    <div className="col-lg-6">
                                        <p><b>Property ID</b><span>RT48</span></p>
                                        <p><b>Price</b><span>$252,000</span></p>
                                        <p><b> Property Size</b><span>1500 Sq Ft</span></p>
                                        <p><b>Bathrooms</b><span>3</span></p>
                                        <p><b>Bedrooms</b><span>2</span></p>
                                    </div>
                                    <div className="col-lg-6">
                                        <p><b>Property ID</b><span>RT48</span></p>
                                        <p><b>Price</b><span>$252,000</span></p>
                                        <p><b> Property Size</b><span>1500 Sq Ft</span></p>
                                        <p><b>Bathrooms</b><span>3</span></p>
                                        <p><b>Bedrooms</b><span>2</span></p>
                                    </div>
                                </div>
                            </div>
                            <div className="bg-light p-4 rounded-4 mb-4">
                                <h4>Address</h4>
                                <div className='row gx-5 '>
                                    <div className="col-lg-6">
                                        <p><b>Address</b><span>10425 Tabor St</span></p>
                                        <p><b>City</b><span>Los Angeles</span></p>
                                        <p><b> State/county </b><span>California</span></p>
                                    </div>
                                    <div className="col-lg-6">
                                        <p><b>Address</b><span>10 Downing Street</span></p>
                                        <p><b>City</b><span>London</span></p>
                                        <p><b> State/county </b><span>Greater London</span></p>
                                    </div>
                                    <div className="col-12">
                                        <iframe className='rounded-4' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3506.4846262568053!2d77.40021531106699!3d28.49506359020877!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390ce85daa8b5b1f%3A0xb3921e54161bc23d!2sSector%20135%2C%20Noida%2C%20Uttar%20Pradesh%20201304!5e0!3m2!1sen!2sin!4v1727786026391!5m2!1sen!2sin" width="100%" height="350" style={{ border: '0' }} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade" title='mechanicalelement'></iframe>
                                    </div>
                                </div>
                            </div>
                            <div className="bg-light p-4 rounded-4 mb-4">
                                <h4>Features & Amenities</h4>
                                <div className="row gx-3 mt-3">
                                    <div className="col-lg-4">
                                        <ul>
                                            <li>Air Condition</li>
                                            <li>Barbeque</li>
                                            <li>Dryer</li>
                                            <li>Gym</li>
                                        </ul>
                                    </div>
                                    <div className="col-lg-4">
                                        <ul>
                                            <li>Air Condition</li>
                                            <li>Barbeque</li>
                                            <li>Dryer</li>
                                            <li>Gym</li>
                                        </ul>
                                    </div>
                                    <div className="col-lg-4">
                                        <ul>
                                            <li>Air Condition</li>
                                            <li>Barbeque</li>
                                            <li>Dryer</li>
                                            <li>Gym</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="bg-light p-4 rounded-4 mb-4">
                                <h4>Energy Class</h4>
                                <p>
                                    <span>Global Energy Performance Index</span><span>A+</span>
                                </p>
                                <p>
                                    <span>Renewable energy performance index</span><span>92.42 kWh / m²a</span>
                                </p>
                                <p>
                                    <span>Energy performance of the building</span><span>00.00 kWh / m²a</span>
                                </p>
                                <p>
                                    <span>EPC Current Rating</span><span>92</span>
                                </p>
                                <p>
                                    <span>EPC Potential Rating</span><span>80+</span>
                                </p>
                                <img className='w-100' src={energyscale} alt="" />
                            </div>
                            <FloorPlans />
                            <VideoProperty />
                            <VirtualTour />
                            <NearByProperty />
                            <WalkScore />
                            <MortgageCalculator />
                            <PropertyView />
                            <HomeValue />
                            <GetMoreInformation />
                            <PropertyReview />
                            <LeaveAReview />
                        </div>
                        <div className="col-lg-4">
                            <div className='bg-light p-3 rounded-4'>
                                <h2>Schedule a tour</h2>
                                <p>Choose your preferred day</p>
                                <div className="buttons d-flex justify-content-center gap-2 mb-4">
                                    <button className='btn border-1 border-dark btn-outline-dark'>In person</button>
                                    <button className='btn border-1 border-dark btn-outline-dark'>Video Chat</button>
                                </div>
                                <form action="/">
                                    <input className="form-control mb-4 form-control-lg" type="text" placeholder='Time' />
                                    <input className="form-control mb-4 form-control-lg" type="text" placeholder='Name' />
                                    <input className="form-control mb-4 form-control-lg" type="text" placeholder='Phone' />
                                    <input className="form-control mb-4 form-control-lg" type="email" placeholder='Email' />
                                    <textarea className="form-control mb-4 form-control-lg" rows="3" placeholder='Your Text'></textarea>
                                    <button className='form-control btn btn-lg btn-dark btn-outline-light'>Submit a Tour Request<GoArrowUpRight /></button>
                                </form>
                            </div>
                            <div className="container mt-4 bg-light rounded-2 p-4">
                                <h5 className='pb-3'>Get More Information</h5>
                                <div className='d-flex justify-content-evenly mb-4'>
                                    <img className='w-25 rounded-circle' src={Dplogo} alt="" />
                                    <p className='d-flex flex-column'>
                                        <span>Arlene McCoy</span>
                                        <span className='text-danger'><IoCallOutline /> (920) 012-3421</span>
                                        <span>View Listings</span>
                                    </p>
                                </div>
                                <button className='btn btn-lg form-control btn-outline-dark'>Contact Agent</button>
                            </div>
                        </div>
                        <DiscoverFeatureProperty />

                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default OverviewPropty
