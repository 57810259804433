// VisitorChart.js
import React, { Fragment } from 'react';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, LineElement, PointElement, Title, Tooltip, Legend } from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, LineElement, PointElement, Title, Tooltip, Legend);

const HomeValue = () => {
    const timeFrame = 'daily';

    const data = {
        daily: [30, 50, 40, 60, 70, 90, 120],
    };

    const labels = {
        daily: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
    };

    const chartData = {
        labels: labels[timeFrame],
        datasets: [
            {
                label: `Visitors (${timeFrame})`,
                data: data[timeFrame],
                backgroundColor: 'rgba(0, 255, 255, .41)',
                borderColor: 'rgba(0, 255, 255, 1)',
                borderWidth: 2,
                fill: true,
                tension: 0.4,
                pointBackgroundColor: 'rgba(0, 255, 255, 1)',
                pointBorderColor: '#fff',
                pointRadius: 5,
            },
        ],
    };

    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: true,
                text: `Number of Visitors (${timeFrame.charAt(0).toUpperCase() + timeFrame.slice(1)})`,
            },
        },
        scales: {
            y: {
                beginAtZero: true,
            },
        },
    };

    return (
        <Fragment>
            <div className="container-fluid property-view-full-container bg-light mt-4 rounded-4">
                <div className='property-view-row'>
                    <h2>Home Value</h2>
                    <Line data={chartData} options={options} />
                </div>
            </div>
        </Fragment>
    );
};

export default HomeValue;

