import React, { Fragment, useEffect } from 'react'
import { FaPersonArrowUpFromLine } from 'react-icons/fa6'
import { GoArrowUpRight, GoCodeReview, GoHome } from 'react-icons/go'
import { IoMdHeartEmpty } from 'react-icons/io'
import DashboardGraph from './DashboardGraph'

import { FaCarAlt } from 'react-icons/fa'
import { MdOutlineRadio } from 'react-icons/md'
import { HiOutlineDesktopComputer, HiOutlineDocumentText, HiOutlineGift } from 'react-icons/hi'
import Axios from 'axios'
import Live_Base_url from '../../Common/Live_Base_Url'

function DashboardHome() {

    const singleUser = JSON.parse(localStorage.getItem("singleUser"));
    const auth = localStorage.getItem("Authorization");


    useEffect(() => async () => {
        try {
            const { data } = await Axios.get(`${Live_Base_url}/me`, {
                headers: {
                    'Authorization': `Bearer ${auth}`
                }
            });
            if (data?.success) {
                console.log(data);
                localStorage.setItem("singleUser", JSON.stringify(data?.data));
            }
        } catch (error) {
            console?.error('Error:', error);
            if (error?.response) {
                console.log('Response error:', error.response.data);
            }
        }
    }, [auth]);



    return (
        <Fragment>
            <div className="dashboard-home-full-container container-fluid">
                <div className="container dashboard-home-container">
                    <div>
                        <h2>Hello, {singleUser?.first_name}</h2>
                        <p>
                            We are glad to see you again!
                        </p>
                    </div>
                    <div className="dash-short-board">
                        <div className="dash-short-board-1">
                            <span>
                                <h3>All Properties</h3>
                                <h2>583</h2>
                            </span>
                            <span className='icons'>
                                <GoHome />
                            </span>
                        </div>
                        <div className="dash-short-board-1">
                            <span>
                                <h3>Total Views</h3>
                                <h2>192</h2>
                            </span>
                            <span className='icons'>
                                <FaPersonArrowUpFromLine />
                            </span>
                        </div>
                        <div className="dash-short-board-1">
                            <span>
                                <h3>Total Visitor Reviews</h3>
                                <h2>438</h2>
                            </span>
                            <span className='icons'>
                                <GoCodeReview />
                            </span>
                        </div>
                        <div className="dash-short-board-1">
                            <span>
                                <h3>Total Favorites</h3>
                                <h2>67</h2>
                            </span>
                            <span className='icons'>
                                <IoMdHeartEmpty />
                            </span>
                        </div>
                    </div>
                    <div className="dash-graph-container container">
                        <div className="row">
                            <div className="col-lg-9">
                                <DashboardGraph />
                            </div>
                            <div className="col-lg-3">
                                <div className="recent-activities mt-4">
                                    <div>
                                        <span>
                                            <div className="icons"><GoHome /></div>
                                            <p>Your listing <b>House on the Beverly Hills</b> has been approved</p>
                                        </span>
                                        <span>
                                            <div className="icons"><IoMdHeartEmpty /></div>
                                            <p>Dollie Horton left a review on <b>House on the Northridge</b></p>
                                        </span>
                                        <span>
                                            <div className="icons"><FaCarAlt /></div>
                                            <p>Someone favorites your <b>Triple Story House for Rent</b> listing</p>
                                        </span>
                                        <span>
                                            <div className="icons"><MdOutlineRadio /></div>
                                            <p>Your listing <b>House on the Beverly Hills </b>has been approved </p>
                                        </span>
                                        <span>
                                            <div className="icons"><HiOutlineDocumentText /></div>
                                            <p>Dollie Horton left a review on <b>House on the Northridge</b> </p>
                                        </span>
                                        <span>
                                            <div className="icons"><HiOutlineDesktopComputer /></div>
                                            <p>Someone favorites your <b>Triple Story House for Rent</b> listing </p>
                                        </span>
                                        <span>
                                            <div className="icons"><HiOutlineGift /></div>
                                            <p>Your listing <b>House on the Beverly Hills </b>has been approved </p>
                                        </span>
                                        <button className='submit-btn'>View More <GoArrowUpRight /></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </Fragment>
    )
}

export default DashboardHome
