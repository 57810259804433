import React, { useState } from 'react';
import axios from 'axios';
import Live_Base_url from '../../Common/Live_Base_Url';
import { Slide, toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const VideosAndPhotos = ({ setActiveTab }) => {
    const [uploadedFiles, setUploadedFiles] = useState([]);
    const [uploadStatus, setUploadStatus] = useState(null); // To show the status of the upload

    // Function to handle files dropped into the drop zone
    const handleDrop = (e) => {
        e.preventDefault();
        const files = Array.from(e.dataTransfer.files);
        setUploadedFiles((prevFiles) => [...prevFiles, ...files]);
    };

    // Prevent default behavior when dragging over the drop zone
    const handleDragOver = (e) => {
        e.preventDefault();
    };

    // Function to handle file selection via file input
    const handleFileChange = (e) => {
        const files = Array.from(e.target.files);
        setUploadedFiles((prevFiles) => [...prevFiles, ...files]);
    };

    const handleRemoveFile = (indexToRemove) => {
        setUploadedFiles((prevFiles) => prevFiles.filter((_, index) => index !== indexToRemove));
    };

    const handleSave = async () => {
        if (uploadedFiles.length === 0) {
            setUploadStatus('No files to upload.');
            return;
        }
        const formData = new FormData();

        uploadedFiles.forEach((file, index) => {
            formData.append(`media_files[${index}]`, file);
        });
        try {
            const token = localStorage.getItem('Authorization');
            const Product_id = localStorage.getItem('Product_id');

            const response = await axios.post(`${Live_Base_url}/properties/${Product_id}/media`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': `${token}`,
                },
            });

            if (response.status === 200) {
                setUploadStatus('Files uploaded successfully.');
                console.log(response.data.message);
                toast.success(response?.data?.message, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    transition: Slide,
                });

            } else {
                setUploadStatus('File upload failed.');
                console.error(response.data.message);
                toast.error(response?.data?.message, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    transition: Slide,
                });
            }
        } catch (error) {
            console.error('Error uploading files:', error);
            setUploadStatus('Error uploading files.');
        }
    };
    return (
        <div className="videos-and-photo">
            <p className='step4'>Step 4</p>
            <h4 className='whatkindh4'>Add photos of your property (optional)</h4>
            <p className='apicture'>A picture is worth a thousand words. 87% of buyers look at photos before buying.</p>
            <div className="videos-and-photo-input-section">
                <h6>Upload from desktop</h6>
                <div className="container d-flex justify-content-center align-items-center p-3">
                    <div className="p-5 text-center upload-box" onDrop={handleDrop} onDragOver={handleDragOver}>
                        <p className="font-weight-bold text-primary">+ Add photos</p>
                        <p>Drag and drop your photos here</p>
                        <p className="text-muted">
                            Upto 50 photos • Max. size 10 MB • Formats: png, jpg, jpeg, gif, webp, heic, heif
                        </p>
                        <input
                            type="file"
                            className="form-control-file"
                            multiple
                            onChange={handleFileChange}
                            accept=".png,.jpg,.jpeg,.gif,.webp,.heic,.heif"
                            style={{ display: 'none' }}
                            id="upload-file"
                        />
                        <label htmlFor="upload-file" className="btn btn-primary mt-3">Upload Photos Now</label>
                    </div>
                </div>
                {uploadedFiles.length > 0 && (
                    <div className="uploaded-files-list mt-3">
                        <h6>Uploaded Photos:</h6>
                        <ul>
                            {uploadedFiles.map((file, index) => (
                                <li key={index} className="d-flex justify-content-between align-items-center">
                                    {file.name}
                                    <button
                                        className="btn btn-link text-danger"
                                        onClick={() => handleRemoveFile(index)}
                                    >
                                        Remove
                                    </button>
                                </li>
                            ))}
                        </ul>
                    </div>
                )}
                {uploadStatus && <p className="text-info">{uploadStatus}</p>}
            </div>
            <div className='basic-btn d-flex gap-3'>
                <button className='submit-btn' onClick={() => setActiveTab('Property-Profile')}>Back</button>
                <button className='submit-btn final-submit' onClick={handleSave}>Save</button>
                <button className='submit-btn' onClick={() => setActiveTab('Pricing&Others')}>Next</button>
            </div>
            <ToastContainer/>
        </div>
    );
};
export default VideosAndPhotos;
