import React, { Fragment } from 'react'
import blog1 from '../../Assets/images/Blog1.jpg';
import blog2 from '../../Assets/images/Blog2.jpg';
import blog3 from '../../Assets/images/Blog3.jpg';

function Blog() {
    return (
        <Fragment>
            <div className="blog-full-container container-fluid">
                <div class="blog-container container">
                    <div class="row">
                        <div class="col-lg-6 m-auto">
                            <div class="main-title text-start text-md-center">
                                <h2 class="title">From Our Blog</h2>
                                <p class="paragraph">Aliquam lacinia diam quis lacus euismod</p>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-6 col-lg-4">
                            <div class="card">
                                <img src={blog1} class="card-img-top" alt="..." />
                                <div class="card-body">
                                    <h5 class="card-title">Card title</h5>
                                    <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                                    
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-6 col-lg-4">
                            <div class="card">
                                <img src={blog2} class="card-img-top" alt="..." />
                                <div class="card-body">
                                    <h5 class="card-title">Card title</h5>
                                    <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                                    
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-6 col-lg-4">
                            <div class="card">
                                <img src={blog3} class="card-img-top" alt="..." />
                                <div class="card-body">
                                    <h5 class="card-title">Card title</h5>
                                    <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default Blog
