import React, { Fragment } from 'react';
import headerlogo from '../../Assets/images/logo-real-estate-home-solution.jpg'
import {  FaFacebook, FaGooglePlay, FaInstagram, FaLinkedin, FaRegHeart, FaWhatsapp } from 'react-icons/fa';
import { FaXTwitter } from 'react-icons/fa6';

function Footer() {
    return (
        <Fragment>
            <div className="container-fluid footer-full-container">
                <div className="footer-container container">
                    <div className="row footer-row">
                        <div className="col-md-4 footer-col">
                            <h1 className='text-light'><img src={headerlogo} alt="nothing" style={{ width: '60px' }} /> RealEstate</h1>
                            <div className='pt-4 d-flex text-light' style={{ fontSize: '11px' }}>
                                <p >
                                    Total Free Customer Care
                                    <br />
                                    +(0) 123 050 945 02
                                </p>
                                <p>
                                    Need Live Support?
                                    <br />
                                    hi@homez.com</p>
                            </div>
                            <p className='h3 text-light pt-2'>Apps</p>
                            <div className="d-flex text-light">

                                {/* <h1><FaApple /></h1><p style={{ fontSize: "11px" }}> Download on the <br />Applestore</p> */}
                                <h2 className='d-flex justify-content-center gap-3'><FaGooglePlay />  <p style={{ fontSize: "11px" }}> Download on the <br />Playstore</p></h2>
                            </div>
                            <p className='text-light fw-bold pt-3'>Follow us on social media</p>
                            <div className="d-flex text-light pe-4">
                                <FaFacebook /><FaXTwitter /><FaInstagram /><FaWhatsapp /> <FaLinkedin />
                            </div>
                        </div>
                        <div className="col-md-8 footer-col pt-5 pt-lg-0">
                            <p className='text-light fw-bold pt-3'>Keep Yourself Up to Date</p>
                            <div className="footer-input">
                                <div class="input-group">
                                    <input type="text" class="form-control" placeholder="Your Email" style={{ outline: 'none', boxShadow: 'none', border: 'none' }} />
                                    <div class="input-group-text"><button className='btn btn-outline-dark'>Subscribe</button></div>
                                </div>
                            </div>
                            <div className="footer-list d-flex flex-row text-light pt-4">
                                <span>
                                    <h1 className='h6'>Popular Search</h1>
                                    <ul style={{ fontSize: '11px', marginLeft: '-25px' }}>
                                        <li>Apartment for Rent</li>
                                        <li>Apartment Low to Hide</li>
                                        <li>Offices for Buy</li>
                                        <li>Offices for Rent</li>
                                    </ul>
                                </span>
                                <span>
                                    <h1 className='h6'>Quick Links</h1>
                                    <ul style={{ fontSize: '11px', marginLeft: '-25px' }}>
                                        <li>Terms of Use</li>
                                        <li>Privacy Policy</li>
                                        <li>Pricing Plans</li>
                                        <li>Our Services</li>
                                        <li>Contact Support</li>
                                        <li>Careers</li>
                                        <li>FAQs</li>
                                    </ul>
                                </span>
                                <span>
                                    <h1 className='h6'>Discover</h1>
                                    <ul style={{ fontSize: '11px', marginLeft: '-25px' }}>
                                        <li>Miami</li>
                                        <li>Los Angeles</li>
                                        <li>Chicago</li>
                                        <li>New York</li>
                                    </ul>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <hr className='text-light' />
                <div className="container footer-footer text-light">
                    <p>© Real Estate 2024 ib-themes - All rights reserved</p>
                    <p>Made By Rudraa <FaRegHeart /></p>
                    <p>Privacy · Terms · Sitemap</p>
                </div>
            </div>
        </Fragment>
    )
}

export default Footer
