import React, { Fragment } from 'react';
import buy from '../../Assets/images/property-buy.svg';
import sell from '../../Assets/images/property-sell.svg';
import rent from '../../Assets/images/property-rent.svg';
import { RiArrowRightUpLine } from 'react-icons/ri';


function HowHelp() {
    return (
        <Fragment>
            <div className="container-fluid py-5">
                <div className="container text-center">
                    <h1>See How RealEstate Can Help</h1>
                    <p>Aliquam lacinia diam quis lacus euismod</p>
                </div>
                <div className="HowHelp container d-flex justify-content-between flex-wrap">
                    <div className="card">
                        <div className="card-header">
                            <img className='image-top' src={buy} alt="" />
                        </div>
                        <div className="card-body">
                            <div className="card-title">
                                <h1>Buy a property</h1>
                            </div>
                            <div className="card-text">
                                Nullam sollicitudin blandit eros eu pretium. Nullam maximus ultricies auctor.
                            </div>
                            <div className="card-button">
                                <button>Find A Home <RiArrowRightUpLine /></button>
                            </div>
                        </div>
                    </div>
                    <div className="card">
                        <div className="card-header">
                            <img className='image-top' src={sell} alt="" />
                        </div>
                        <div className="card-body">
                            <div className="card-title">
                                <h1>Sell a property</h1>
                            </div>
                            <div className="card-text">
                                Nullam sollicitudin blandit eros eu pretium. Nullam maximus ultricies auctor.
                            </div>
                            <div className="card-button">
                                <button>Place an ad <RiArrowRightUpLine /></button>
                            </div>
                        </div>
                    </div>
                    <div className="card">
                        <div className="card-header">
                            <img className='image-top' src={rent} alt="" />
                        </div>
                        <div className="card-body">
                            <div className="card-title">
                                <h1>Rent a property</h1>
                            </div>
                            <div className="card-text">
                                Nullam sollicitudin blandit eros eu pretium. Nullam maximus ultricies auctor.
                            </div>
                            <div className="card-button">
                                <button>Find a rental <RiArrowRightUpLine /></button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default HowHelp
