import React, { Fragment, useState } from 'react';
import { CiHeart } from 'react-icons/ci';
import { FaShower } from 'react-icons/fa';
import { GoShareAndroid } from 'react-icons/go';
import { IoBedOutline } from 'react-icons/io5';
import { LuCopyPlus } from 'react-icons/lu';
import { PiResizeFill } from 'react-icons/pi';

function HeaderProperty() {
    const [active, setAvtive] = useState(false);
    const handleActive = () => {
        setAvtive(!active);
    }

    return (
        <Fragment>
            <div className="property-full-container container-fluid">
                <div className="property-container container pt-5">
                    <div className="d-lg-flex justify-content-between">
                        <div>
                            <h1>Equestrian Family Home</h1>
                            <p className='d-flex gap-4'>
                                <span>New York City, CA, USA</span>
                                <span className='bg-dark text-light px-1'> For sale</span>
                                <span> 6 years ago</span>
                                <span> 8721</span>
                            </p>
                            <p className='d-flex gap-3'>
                                <span><IoBedOutline /> 1 Bed</span>
                                <span><FaShower /> 2 Bath</span>
                                <span><PiResizeFill /> 1200 sqft</span>
                            </p>
                        </div>
                        <div className='HCS'>

                            <p className='d-flex gap-4'>
                                <button className='btn border-1 border-dark'><CiHeart /></button>
                                <button className='btn border-1 border-dark'> <LuCopyPlus /></button>
                                <button className='btn border-1 border-dark ' onClick={handleActive}> <GoShareAndroid />
                                    <ul className={`mt-2 share-menu ${active ? "active" : ""}`}>
                                        <a className='share-btn twitter' href="https://twitter.com/share?url=<URL>&text=<TEXT>via=<USERNAME>">
                                            Twitter
                                        </a>
                                        <a className='share-btn facebook' href="https://www.facebook.com/sharer/sharer.php?u=<URL>">
                                            Facebook
                                        </a>
                                        <a className='share-btn reddit' href="https://reddit.com/submit?url=<URL>&title=<TITLE>">
                                            Reddit
                                        </a>
                                        <a className='share-btn hackernews' href="https://news.ycombinator.com/submitlink?u=<URL>&t=<TITLE>">
                                            Hacker News
                                        </a>
                                        <a className='share-btn linkedin' href="https://www.linkedin.com/shareArticle?url=<URL>&title=<TITLE>&summary=<SUMMARY>&source=<SOURCE_URL>">
                                            LinkedIn
                                        </a>
                                        <a className='share-btn email' href="mailto:?subject=<SUBJECT>&body=<BODY>">
                                            Email
                                        </a>
                                    </ul>
                                </button>
                            </p>
                            <h2 className='text-end'>
                                $14,000
                            </h2>
                            <p className='text-end'>
                                $11.67/sq ft
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default HeaderProperty
