import React, { Fragment } from 'react'
import Header from '../Common/Header'
import HeaderList from './HeaderList'
import Footer from '../Common/Footer'

function HomeList() {
    return (
        <Fragment>
            <div className="homelist-header">
                <Header />
            </div>
            <HeaderList />
            <div className="contaienr-fluidmt-4">
            <Footer/>
            </div>
        </Fragment>
    )
}

export default HomeList
