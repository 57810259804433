import React, { Fragment } from 'react';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import ReactOwlCarousel from 'react-owl-carousel';
import house1 from '../../Assets/images/house1.jpg';
import house2 from '../../Assets/images/House2.jpg';
import house3 from '../../Assets/images/house3.jpg';
import house4 from '../../Assets/images/House4.jpg';
import { IoBedOutline } from 'react-icons/io5';
import { FaShower } from 'react-icons/fa';
import { PiResizeFill } from 'react-icons/pi';
import { RiArrowRightUpLine } from 'react-icons/ri';
import { CiHeart } from 'react-icons/ci';
import { BiLike } from 'react-icons/bi';
import { MdOutlineLibraryAddCheck } from 'react-icons/md';

const Property = [
    {
        "id": 1,
        "img": `${house1}`
    },
    {
        "id": 2,
        "img": `${house2}`
    },
    {
        "id": 3,
        "img": `${house3}`
    },
    {
        "id": 4,
        "img": `${house4}`
    }
]


function SecondDiscover() {
    const options = {
        loop: false,
        autoplay: false,
        autoplayTimeout: 1500,
        nav: true,
        dots: true,
        responsive: {
            0: {
                items: 1
            },
            600: {
                items: 1
            },
            1000: {
                items: 3
            },
            1200: {
                items: 4
            }
        }
    };
    return (
        <Fragment>
            <div className="second-discover-full-container container-fluid">
                <div className="conatiner" style={{ padding: '10px' }}>

                    <div className="row">
                        <div className="col-lg-6">
                            <section className="second-discover-section">
                                <h1>Discover Popular Properties</h1>
                                <p>
                                    Aliquam lacinia diam quis lacus euismod
                                </p>
                            </section>
                        </div>
                        <div className="col-lg-6">
                            <section className='text-lg-end'>
                                <button className='btn btn-outline-light me-2'>For Rent</button>
                                <button className='btn btn-outline-light'>For Sale</button>
                            </section>
                        </div>
                    </div>
                    {/* Owl Carousel */}
                    <section className='second-discover-owl-section'>
                        <ReactOwlCarousel className='owl-theme' {...options}>
                            {Property.map((d) => {
                                return (
                                    <div className='item'>
                                        <div class="card">
                                            <img src={`${d.img}`} class="card-img" alt="..." />
                                            <div className="card-header">
                                                Card title
                                            </div>
                                            <div className="card-body">
                                                <div className="card-title">
                                                    New York City, CA ,USA
                                                </div>
                                                <div className="card-text card-content">
                                                    <ul>
                                                        <li><IoBedOutline /> 1</li>
                                                        <li> bed</li>
                                                    </ul>
                                                    <ul>
                                                        <li><FaShower /> 2</li>
                                                        <li>bath</li>
                                                    </ul>
                                                    <ul>
                                                        <li><PiResizeFill /> 1200</li>
                                                        <li>sqft</li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <hr />
                                            <div className="card-footer">
                                                <p>For Rent {'\u00A0'}{'\u00A0'}{'\u00A0'}</p>
                                                <p><CiHeart /> <BiLike /> <MdOutlineLibraryAddCheck /></p>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}

                        </ReactOwlCarousel>
                        <div className="second-dicover-owl-section-button text-center p-5">
                            <button className='submit-btn bg-light border-light' style={{width:"10rem"}}>See All Option <RiArrowRightUpLine /></button>
                        </div>
                    </section>
                </div>
            </div>
        </Fragment>
    )
}

export default SecondDiscover
