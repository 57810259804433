import React, { Fragment, useEffect, useRef, useState } from 'react';
import noUiSlider from "nouislider";
import { FiHome } from "react-icons/fi";
import { CiSearch } from "react-icons/ci";
import { AiOutlineMenuUnfold } from "react-icons/ai";
import { GrFormSubtract } from 'react-icons/gr';
import { GiReturnArrow } from 'react-icons/gi';

function HeroSection() {
  const [active, setActive] = useState(false);
  const sliderRef = useRef(null);
  const [minValue, setMinValue] = useState(20);
  const [maxValue, setMaxValue] = useState(100000);

  useEffect(() => {
    if (active) {
      document.body.classList.add('no-scroll');
    } else {
      document.body.classList.remove('no-scroll');
    }

    return () => {
      document.body.classList.remove('no-scroll');
    };
  }, [active]);
  useEffect(() => {
    const slider = sliderRef.current;
    if (slider) {
      noUiSlider.create(slider, {
        start: [minValue, maxValue],
        connect: true,
        behaviour: 'tap-drag',
        range: {
          min: 0,
          max: 100000,
        },
        format: {
          to: (value) => Math.round(value),
          from: (value) => Number(value),
        },
      });

      // Listen to slider updates
      sliderRef.current.noUiSlider.on("update", (values) => {
        setMinValue(values[0]);
        setMaxValue(values[1]);
      });
    }
    return () => {
      if (slider && slider.noUiSlider) {
        slider.noUiSlider.destroy();
      }
    };
  }, [minValue, maxValue]);

  return (
    <Fragment>
      <div className="header">
        <div className="hero-section container-fluid">
          <div className="hero d-flex flex-column justify-content-center align-items-center">
            <div className="content">
              <h6>
                THE BEST WAY TO
              </h6>
              <h1>
                Find Your Dream Home
              </h1>
              <p>
                We’ve more than 745,000 apartments, place & plot.
              </p>
            </div>
            <div className="content">
              <span className="content-outside-button">
                <button className="btn">Sell</button>
                <button className="btn">Rent</button>
                <button className="btn">Buy</button>
              </span>
              <div className="content-both d-sm-flex justify-content-center align-items-center">
                <div className="content-input">
                  <span className='content-input-home'><FiHome /></span>
                  <input type="text" placeholder="Search for your dream home" />
                </div>
                <span className='content-button d-flex align-items-center'>
                  <button style={{ textWrap: 'nowrap' }} onClick={() => {
                    setActive(!active);
                  }}><AiOutlineMenuUnfold />Advanced</button>
                  <button><CiSearch /></button>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={`container advanced-container ${active ? "active" : ''}`}>
        <div className="advanced-content">
          <div className="advanced-content-top">
            <h3>More Filter</h3>
            <button className='btn btn-outline-dark border-white fs-5' onClick={() => {
              setActive(false);
            }}>X</button>
          </div>
          <hr />
          <div className="advanced-middle-content">
            <div className="">
              <label className='pb-1' htmlFor="price_range">Price Range</label>
              <div className="full-price-range">
                <div id="slider-range" ref={sliderRef}></div>
              </div>
              <div className="d-flex justify-content-center align-items-center gap-3 pt-3">
                <button className='btn border-2 border-dark'>${minValue}</button>
                <span><GrFormSubtract /></span>
                <button className='btn border-2 border-dark'>${maxValue}</button>
              </div>
              <div className=' row g-3'>
                <div className="col-lg-6">
                  <label htmlFor="type">Type</label>
                  <div className="input-group">
                    <select className=" btn w-100 border border-dark border-1  input-group-text fs-6">
                      <option value="1">Banglow</option>
                      <option value="2">House</option>
                      <option value="3">Apartment</option>
                      <option value="4">Flat</option>
                      <option value="5">Office</option>
                      <option value="6">Villa</option>
                      <option value="7">Town</option>
                    </select>
                  </div>
                </div>
                <div className="col-lg-6">
                  <label htmlFor="type">Property ID</label>
                  <input type="text" className='input-id' placeholder='RT04727283784728' />
                </div>
                <div className="col-lg-6">
                  <div className="label-btn">
                    <label htmlFor="type">Bedrooms</label>
                    <button className='btn'>any</button>
                    <button className='btn'>1+</button>
                    <button className='btn'>2+</button>
                    <button className='btn'>3+</button>
                    <button className='btn'>4+</button>
                    <button className='btn'>5+</button>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="label-btn">
                    <label htmlFor="type">Bathrooms</label>
                    <button className='btn'>any</button>
                    <button className='btn'>1+</button>
                    <button className='btn'>2+</button>
                    <button className='btn'>3+</button>
                    <button className='btn'>4+</button>
                    <button className='btn'>5+</button>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="label-btn">
                    <label htmlFor="type">Location</label>
                    <div className="input-group">
                      <select className=" btn w-100 border border-dark border-1  input-group-text fs-6">
                        <option value="1">All</option>
                        <option value="2">India</option>
                        <option value="3">Japan</option>
                        <option value="4">USA</option>
                        <option value="5">Mauritius</option>
                        <option value="6">Pattaya City</option>
                        <option value="7">Hong kong</option>
                        <option value="8">California</option>
                        <option value="9">Los Angeles</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <label htmlFor="type">Square Feet</label>
                  <div className="d-flex justify-content-center gap-2">
                    <input type="number" placeholder='min' className='form-control border-1' />
                    <input type="number" placeholder='max' className='form-control border-1' />
                  </div>
                </div>
                <div className="col-lg-12">
                  <label htmlFor="Amenities">Amenities</label>
                  <div className='row'>
                    <span className='col-lg-4 col-6 d-flex gap-1'><input type="checkbox" className='' /><span>Attic</span></span>
                    <span className='col-lg-4 col-6 d-flex gap-1'><input type="checkbox" className='' /><span>Tv Cable</span></span>
                    <span className='col-lg-4 col-6 d-flex gap-1'><input type="checkbox" className='' /><span>Lake View</span></span>
                    <span className='col-lg-4 col-6 d-flex gap-1'><input type="checkbox" className='' /><span>Basketball Court</span></span>
                    <span className='col-lg-4 col-6 d-flex gap-1'><input type="checkbox" className='' /><span>Dryer</span></span>
                    <span className='col-lg-4 col-6 d-flex gap-1'><input type="checkbox" className='' /><span>Wine celler</span></span>
                    <span className='col-lg-4 col-6 d-flex gap-1'><input type="checkbox" className='' /><span>Air Conditioning</span></span>
                    <span className='col-lg-4 col-6 d-flex gap-1'><input type="checkbox" className='' /><span>Outdoor Shower</span></span>
                    <span className='col-lg-4 col-6 d-flex gap-1'><input type="checkbox" className='' /><span>Front yard</span></span>
                    <span className='col-lg-4 col-6 d-flex gap-1'><input type="checkbox" className='' /><span>Lawn</span></span>
                    <span className='col-lg-4 col-6 d-flex gap-1'><input type="checkbox" className='' /><span>Washer</span></span>
                    <span className='col-lg-4 col-6 d-flex gap-1'><input type="checkbox" className='' /><span>Refrigerator</span></span>
                  </div>
                  <hr />
                </div>
                <div className="col-lg-12 d-flex justify-content-between">
                  <span className='d-flex gap-1 align-items-center'><GiReturnArrow /> <button type='reset' className='btn'>Reset all filters</button></span>
                  <span className='d-flex gap-1 align-items-center'> <button type='reset' className='submit-btn w-100'>Submit</button></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={`advanced-overlay ${active ? "active" : ''}`} onClick={() => {
        setActive(false);
      }}></div>
    </Fragment>
  )
}

export default HeroSection
