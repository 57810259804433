import React, { useState } from 'react';
import axios from 'axios'; // Make sure to install axios with npm or yarn
import Live_Base_url from '../../Common/Live_Base_Url';
import { Slide, ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const BasicDetails = ({ setActiveTab }) => {
    const [activeBtn, setActiveBtn] = useState(true);
    const [kindOf, setKindOf] = useState('');
    const [commercial, setCommercial] = useState('');
    const [hospitalityBtn, setHospitalityBtn] = useState(true);

    const [formData, setFormData] = useState({
        listing_type: activeBtn ? 'Sell' : 'Rent/Lease',
        property_type: kindOf,
        accommodation_type: commercial,
        space_type: hospitalityBtn ? 'Hotel/Resort' : 'Guest-House/Banquet-Halls',
    });

    const handleSubmit = async () => {
        try {
            const token = localStorage.getItem('Authorization');

            const response = await axios.post(`${Live_Base_url}/properties/basic-details`, formData, {
                headers: {
                    Authorization: `${token}`,
                    'Content-Type': 'application/json',
                },
            });

            console.log('Response:', response.data);
            if (response?.data?.success) {

                localStorage.setItem("Product_id",response?.data?.property_id);

                toast.success(response?.data?.message, {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    transition: Slide,
                });
            }
            else{
                toast.error(response?.data?.message, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    transition: Slide,
                });
            }

        } catch (error) {
            console.error('Error submitting form:', error);
        }
    };

    const lookingInputRadio = () => {
        if (kindOf === 'residential') {
            return (
                <div className='group-of-button d-flex flex-wrap gap-3 pt-2'>
                    <button className={`btn `}>Flat/Apartment</button>
                    <button className={`btn `}>Independent/Villa</button>
                    <button className={`btn `}>Independent/Builder Floor</button>
                    <button className={`btn `}>Plot/Land</button>
                    <button className={`btn `}>1 Rk/Studio Apartment</button>
                    <button className={`btn `}>Serviced/Apartment</button>
                    <button className={`btn `}>Farmhouse </button>
                    <button className={`btn `}>Other</button>
                </div>
            );
        } else if (kindOf === 'commercial') {
            return (
                <div>
                    <div className='group-of-button d-flex flex-wrap gap-3 pt-2'>
                        <button className={`btn ${commercial === 'Retail' ? 'active' : ''}`} onClick={() => {
                            setCommercial('Retail');
                            setFormData({ ...formData, accommodation_type: 'Retail' }); // Update form data
                        }}>Retail</button>
                        <button className={`btn ${commercial === 'Office' ? 'active' : ''}`} onClick={() => {
                            setCommercial('Office');
                            setFormData({ ...formData, accommodation_type: 'Office' });
                        }}>Office</button>
                        <button className={`btn ${commercial === 'Hospitality' ? 'active' : ''}`} onClick={() => {
                            setCommercial('Hospitality');
                            setFormData({ ...formData, accommodation_type: 'Hospitality' });
                        }}>Hospitality</button>
                        <button className={`btn ${commercial === 'Warehouse' ? 'active' : ''}`} onClick={() => {
                            setCommercial('Warehouse');
                            setFormData({ ...formData, accommodation_type: 'Warehouse' });
                        }}>Warehouse</button>
                        <button className={`btn ${commercial === 'Plot' ? 'active' : ''}`} onClick={() => {
                            setCommercial('Plot');
                            setFormData({ ...formData, accommodation_type: 'Plot' });
                        }}>Plot/Land</button>
                        <button className={`btn ${commercial === 'Garage' ? 'active' : ''}`} onClick={() => {
                            setCommercial('Garage');
                            setFormData({ ...formData, accommodation_type: 'Garage' });
                        }}>Garage</button>
                        <button className={`btn ${commercial === 'Social' ? 'active' : ''}`} onClick={() => {
                            setCommercial('Social');
                            setFormData({ ...formData, accommodation_type: 'Social' });
                        }}>Social </button>
                    </div>
                    {lookingInputRadioCommercial()}
                </div>
            );
        } else {
            return null;
        }
    };

    const lookingInputRadioCommercial = () => {
        switch (commercial) {
            case 'Retail':
                return <h4>Hello Retail</h4>;
            case 'Office':
                return <h4>Hello Office</h4>;
            case 'Hospitality':
                return (
                    <div className='pt-4'>
                        <h4 className='whatkindh4'>What kind of Hospitality is it?</h4>
                        <div className="group-of-button d-flex gap-3 flex-wrap">
                            <button className={`btn ${hospitalityBtn ? 'active' : ''}`} onClick={() => {
                                setHospitalityBtn(true);
                                setFormData({ ...formData, space_type: 'Hotel/Resort' });
                            }}>Hotel/Resort</button>
                            <button className={`btn ${hospitalityBtn ? '' : 'active'}`} onClick={() => {
                                setHospitalityBtn(false);
                                setFormData({ ...formData, space_type: 'Guest-House/Banquet-Halls' });
                            }}>Guest-House/Banquet-Halls</button>
                        </div>
                    </div>
                );
            case 'Warehouse':
                return <div className="pt-4"><h4>Hello Warehouse</h4></div>;
            case 'Plot':
                return <h4>Hello Plot</h4>;
            case 'Garage':
                return <h4>Hello Garage</h4>;
            case 'Social':
                return <h4>Hello Social</h4>;
            default:
                return null;
        }
    };

    return (
        <div className="Basic-Details-container">
            <p className='step1'>Step 1</p>
            <div className="looking-input my-3">
                <h4 className='whatkindh4'>I'm looking to</h4>
                <span className='span-btn'>
                    <button className={`btn ${activeBtn ? 'active' : ''}`}
                        onClick={() => {
                            setActiveBtn(true);
                            setFormData({ ...formData, listing_type: 'Sell' });
                        }}>Sell</button>
                    <button className={`btn ${activeBtn ? '' : 'active'}`}
                        onClick={() => {
                            setActiveBtn(false);
                            setFormData({ ...formData, listing_type: 'Rent/Lease' });
                        }}>Rent/Lease</button>
                </span>
            </div>
            <div className="looking-input my-3 d-flex flex-column">
                <h4 className='whatkindh4'>What kind of property do you have?</h4>
                <div className='span-radio d-flex gap-3 justify-content-start align-items-center'>
                    <div className="form-check d-flex align-items-center gap-2">
                        <input type="radio" name="flexRadioDefault" id="flexRadioDefault1" onClick={() => {
                            setKindOf('residential');
                            setFormData({ ...formData, property_type: 'residential' });
                        }} />
                        <label htmlFor="flexRadioDefault1">Residential</label>
                    </div>
                    <div className="form-check d-flex align-items-center gap-2">
                        <input type="radio" name="flexRadioDefault" id="flexRadioDefault2" onClick={() => {
                            setKindOf('commercial');
                            setFormData({ ...formData, property_type: 'commercial' });
                        }} />
                        <label htmlFor="flexRadioDefault2">Commercial</label>
                    </div>
                </div>
                {lookingInputRadio()}
            </div>
            <div className="basic-btn d-flex gap-3">
                <button className="submit-btn" onClick={() => setActiveTab('Basic-Details')}>Back</button>
                <button className="submit-btn final-submit" onClick={handleSubmit}>Save</button>
                <button className="submit-btn" onClick={() => setActiveTab('Location-Details')}>Next</button>
            </div>
            <ToastContainer />
        </div>
    );
};

export default BasicDetails;
