import React from 'react';
import TestiMonialsDetails from './TestiMonialsDetails/TestiMonialsDetails'
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import thumb from '../../Assets/images/right-thumb.png'


const TestiMonials = () => {

  const testiMonials = [
    {
      name: 'Rakesh Kumar',
      description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s.',
      address: 'India',
      img: 'https://i.ibb.co/hgGJc8d/Gareth-Bale.jpg'
    },
    {
      name: 'Shyam Singh',
      description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s.',
      address: 'India',
      img: 'https://i.ibb.co/z7Kp6yr/np-file-33188.jpg'
    },
    {
      name: 'Birju Khagaria',
      description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s.',
      address: 'India',
      img: 'https://i.ibb.co/CP5sj7g/2856040-58866808-2560-1440.jpg'
    },
    {
      name: 'Parvej Anand',
      description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s.',
      address: 'India',
      img: 'https://i.ibb.co/10SYccm/1552313010-354215-noticia-normal.jpg'
    },
  ]
  const options = {
    loop: true,
    center: true,
    items: 3,
    margin: 0,
    autoplay: true,
    dots: true,
    autoplayTimeout: 1500,
    smartSpeed: 450,
    nav: false,
    responsive: {
      0: {
        items: 1
      },
      600: {
        items: 3
      },
      1000: {
        items: 3
      },
    }
  };
  return (
    <section id="testimonial" className="testimonials">
      <div className="container">
        <div className="text-center ">
          <h3 className="sectionTitle">What Our Clients are Saying?</h3>
        </div>
        <p className="text-center ">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.</p>
        <div className="row">
          <div className="col-md-12">
            <OwlCarousel id="customer-testimonoals" className="owl-carousel owl-theme" {...options}>
              {
                testiMonials.length === 0 ?
                  <div class="item">
                    <div class="shadow-effect">
                      <img class="img-circle" src={thumb} alt='together thumb' />

                      <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.</p>
                    </div>
                    <div class="testimonial-name">
                      <h5>Rajon Rony</h5>
                      <small>ITALY</small>
                    </div>
                  </div> :
                  testiMonials.map(testiMonialDetail => {
                    return (
                      <TestiMonialsDetails testiMonialDetail={testiMonialDetail} key={testiMonialDetail._key} />
                    )
                  })
              }
            </OwlCarousel>
          </div>
        </div>
      </div>
    </section>
  );
};

export default TestiMonials;