import React, { Fragment, useEffect, useState } from 'react'
import Header from '../Common/Header'
import { Link, Outlet, useNavigate } from 'react-router-dom'
import { AiOutlineDashboard } from 'react-icons/ai'
import { MdAddHomeWork, MdPreview } from 'react-icons/md'
import { GoHome } from 'react-icons/go'
import { FaRegHeart } from 'react-icons/fa'
import { CgSearchLoading } from 'react-icons/cg'
import { LuMenuSquare, LuPackageOpen } from 'react-icons/lu'
import { ImProfile } from 'react-icons/im'
import { BiLogOut } from 'react-icons/bi'

function Dashboard() {
    const [active, setActive] = useState('Dashboard');
    const Navigate = useNavigate();
    const auth = localStorage.getItem("Authorization");

    useEffect(() => {
        if (!auth) {
            localStorage.clear();
            Navigate('/register');
        }

    }, [Navigate, auth]);

    return (
        <Fragment>
            <div className="homelist-header">
                <Header />
            </div>
            <div className=" container-fluid">
                {/* row */}
                <div className="row">
                    <div className="dashboard-left-column col-lg-3 col-xl-2">
                        <div className="expand-button" data-bs-toggle="collapse" data-bs-target="#collapseExample" aria-expanded="true" aria-controls="collapseExample">
                            <button className='btn d-lg-none'> <LuMenuSquare /> <span className='fs-6'>Dashboard Navigation</span></button>
                        </div>
                        <div className='collapse' id="collapseExample">
                            <div className='main-sidebar'>
                                <h6>Main</h6>
                                <Link to="home"><button className={`btn ${active === 'Dashboard' ? "active" : ''}`} onClick={() => { setActive('Dashboard') }}><span><AiOutlineDashboard /></span>Dashboard </button></Link>
                            </div>
                            <div className="manage-listing">
                                <h6>MANAGE LISTINGS</h6>
                                <Link to="add-new-property"><button className={`btn ${active === 'addnewproperty' ? "active" : ''}`} onClick={() => { setActive('addnewproperty') }}> <span><MdAddHomeWork /> </span>Add New Property</button></Link>
                                <Link to="/"><button className={`btn ${active === 'myproperty' ? "active" : ''}`} onClick={() => { setActive('myproperty') }}> <span> <GoHome /></span>My Properties</button></Link>
                                <Link to="/"><button className={`btn ${active === 'myfavorite' ? "active" : ''}`} onClick={() => { setActive('myfavorite') }}> <span> <FaRegHeart /></span>My Favorites</button></Link>
                                <Link to="/"><button className={`btn ${active === 'saved' ? "active" : ''}`} onClick={() => { setActive('saved') }}> <span> <CgSearchLoading /></span>Saved Search</button></Link>
                                <Link to="/"><button className={`btn ${active === 'reviews' ? "active" : ''}`} onClick={() => { setActive('reviews') }}> <span> <MdPreview /></span>Reviews</button></Link>

                            </div>
                            <div className="manage-account">
                                <h6>MANAGE ACCOUNT</h6>
                                <Link to="/"> <button className={`btn ${active === 'mypackage' ? "active" : ''}`} onClick={() => { setActive('mypackage') }}><span><LuPackageOpen /> </span>My Package</button> </Link>
                                <Link to="/"><button className={`btn ${active === 'myprofile' ? "active" : ''}`} onClick={() => { setActive('myprofile') }}><span> <ImProfile /></span>My Profile</button></Link>
                                <Link><button className={`btn ${active === 'logout' ? "active" : ''}`} onClick={() => {
                                    setActive('logout');
                                    localStorage.clear();
                                    Navigate("/register");
                                }}><span> <BiLogOut /></span>Logout</button>    </Link>

                            </div>
                        </div>
                    </div>
                    <div className="col-lg-9 col-xl-10 dashboard-right-column ">
                        <Outlet />
                    </div>
                </div >
            </div >

        </Fragment >
    )
}

export default Dashboard
