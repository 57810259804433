import React, { Fragment } from 'react';
import gallery1 from '../../Assets/images/gallery1.jpg';
import gallery2 from '../../Assets/images/gallery2.jpg';
import gallery3 from '../../Assets/images/gallery3.jpg';
import gallery4 from '../../Assets/images/gallery4.jpg';
import gallery5 from '../../Assets/images/gallery5.jpg';



function GalleryProperty() {
    return (
        <Fragment>
            <div className="gallery-full-container container-fluid">
                <div className="gallery-container container">
                    <div className="row g-3">
                        <div className="col-lg-6">
                            <div className="row g-1">
                                <div className="col-lg-12">
                                    <div className='img-div'>
                                        <img className='gal1 rounded-3' src={gallery1} alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="row g-2">
                                <div className="col-lg-6">
                                    <div className='img-div'>
                                        <img className='gal2' src={gallery2} alt="" />
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className='img-div'>
                                        <img className='gal3' src={gallery3} alt="" />
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className='img-div'>
                                        <img className='gal4' src={gallery4} alt="" />
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className='img-div'>
                                        <img className='gal5' src={gallery5} alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default GalleryProperty
