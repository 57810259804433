import React, { Fragment } from 'react';
import first from '../../Assets/images/first-floor.jpg';
import second from '../../Assets/images/second-floor.jpg';
import third from '../../Assets/images/third-floor.jpg';

function FloorPlans() {

    return (
        <Fragment>
            <div className="floor-plans-full-container container-fluid bg-light rounded-4">
                <h3>Floor Plans</h3>
                <div class="accordion" id="accordionExample">
                    <div class="accordion-item">
                        <h2 class="accordion-header" id="headingOne">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#FirstFloor" aria-expanded="true" aria-controls="FirstFloor">
                                <span class="w-100 d-md-flex align-items-center">
                                    <span><b>First Floor</b></span>
                                    <span class="ms-auto d-md-flex align-items-center justify-content-end">
                                        <span class="me-2 me-md-4">
                                            <span class="fw600"><b> Size:</b></span>
                                            <span class="text">1267 Sqft</span>
                                        </span>
                                        <span class="me-2 me-md-4">
                                            <span class="fw600"><b>Bedrooms</b></span>
                                            <span class="text">2</span>
                                        </span>
                                        <span class="me-2 me-md-4">
                                            <span class="fw600"><b>Bathrooms</b></span>
                                            <span class="text">2</span>
                                        </span>
                                        <span>
                                            <span class="fw600"><b>Price</b></span>
                                            <span class="text">$920,99</span>
                                        </span>
                                    </span>
                                </span>
                            </button>
                        </h2>
                        <div id="FirstFloor" class="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                            <div class="accordion-body">
                                <img src={first} alt="" />
                                Lorem, ipsum dolor sit amet consectetur adipisicing elit. Vero quis quo doloremque, voluptates laudantium magni architecto nostrum recusandae animi eaque! Tempore saepe veniam magni laboriosam. Laboriosam autem vitae impedit eaque.
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item">
                        <h2 class="accordion-header" id="headingTwo">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#SecondFloor" aria-expanded="false" aria-controls="SecondFloor">
                                <span class="w-100 d-md-flex align-items-center">
                                    <span><b>Second Floor</b></span>
                                    <span class="ms-auto d-md-flex align-items-center justify-content-end">
                                        <span class="me-2 me-md-4">
                                            <span class="fw600"><b>Size:</b></span>
                                            <span class="text">1267 Sqft</span>
                                        </span>
                                        <span class="me-2 me-md-4">
                                            <span class="fw600"><b>Bedrooms</b></span>
                                            <span class="text">2</span>
                                        </span>
                                        <span class="me-2 me-md-4">
                                            <span class="fw600"><b>Bathrooms</b></span>
                                            <span class="text">2</span>
                                        </span>
                                        <span>
                                            <span class="fw600"><b>Price</b></span>
                                            <span class="text">$920,99</span>
                                        </span>
                                    </span>
                                </span>
                            </button>
                        </h2>
                        <div id="SecondFloor" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                            <div class="accordion-body">
                                <img src={second} alt="" />
                                Lorem ipsum dolor sit, amet consectetur adipisicing elit. Velit nulla error voluptas quisquam officia, animi nam fuga veritatis expedita deserunt! Sequi, id dolorum. Vitae quibusdam amet earum modi deleniti quidem?
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item">
                        <h2 class="accordion-header" id="headingThree">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#ThirdFloor" aria-expanded="false" aria-controls="ThirdFloor">
                                <span class="w-100 d-md-flex align-items-center">
                                    <span><b>Third Floor</b></span>
                                    <span class="ms-auto d-md-flex align-items-center justify-content-end">
                                        <span class="me-2 me-md-4">
                                            <span class="fw600"><b>Size:</b></span>
                                            <span class="text">1267 Sqft</span>
                                        </span>
                                        <span class="me-2 me-md-4">
                                            <span class="fw600"><b>Bedrooms</b></span>
                                            <span class="text">2</span>
                                        </span>
                                        <span class="me-2 me-md-4">
                                            <span class="fw600"><b>Bathrooms</b></span>
                                            <span class="text">2</span>
                                        </span>
                                        <span>
                                            <span class="fw600"><b>Price</b></span>
                                            <span class="text">$920,99</span>
                                        </span>
                                    </span>
                                </span>
                            </button>
                        </h2>
                        <div id="ThirdFloor" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                            <div class="accordion-body">
                                <img src={third} alt="" />
                                Lorem ipsum dolor sit amet consectetur, adipisicing elit. Dolores maiores obcaecati nulla eum sequi voluptatum omnis, molestiae, magnam expedita sit eos sunt ducimus repellat accusamus vel veritatis hic voluptate tempora.
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </Fragment>
    )
}

export default FloorPlans
