import React, { Fragment } from 'react'
import { GoArrowUpRight } from 'react-icons/go'

function LeaveAReview() {
    return (
        <Fragment>
            <div className="container-fluid leave-a-review-information-full-container bg-light mt-4 rounded-4">
                <div className="leave-a-review-information-container">
                    <h2>Get More Information</h2>
                    <div className="leave-a-review-content">
                        <div class="container">
                            <div class="d-lg-flex justify-content-between">
                                <div class="form-group w-100 mt-3">
                                    <label for="email">Email</label>
                                    <input class="form-control" style={{ height: "60px" }} type="Email" id="email" placeholder="Your Email" />
                                </div>
                            </div>
                            <div class="d-lg-flex justify-content-between gap-3">
                                <div class="form-group w-100 mt-3">
                                    <label for="Title">Title</label>
                                    <input class="form-control" style={{ height: "60px" }} type="text" id="Title" placeholder="Title" />
                                </div>
                                <div class="form-group w-100 mt-3">

                                    <label for="Title">Rating</label>
                                    <select className='form-control' style={{ height: '60px' }} name="rating" id="rating">
                                        <option hidden>Choose Rating</option>
                                        <option value="1">1 ⭐</option>
                                        <option value="2">2 ⭐⭐</option>
                                        <option value="3">3 ⭐⭐⭐</option>
                                        <option value="4">4 ⭐⭐⭐⭐</option>
                                        <option value="5">5 ⭐⭐⭐⭐⭐</option>
                                    </select>
                                </div>
                            </div>
                            <div class="d-lg-flex justify-content-between gap-3">
                                <div class="form-group w-100 mt-3">
                                    <label for="property-tax">Review</label>
                                    <textarea class="form-control" rows={5} placeholder="Write Your Words" ></textarea>
                                </div>
                            </div>
                            <div className="container text-center mt-3 p-lg-4 p-2">
                                <button class="submit-btn"><span>Calculate</span>    <GoArrowUpRight /></button>
                            </div>
                        </div>


                    </div>
                </div>

            </div>
        </Fragment>
    )
}

export default LeaveAReview
