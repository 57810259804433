import React, { useState } from 'react';
import BasicDetails from './BasicDetails';
import LocationDetails from './LocationDetails';
import PropertyProfile from './PropertyProfile';
import VideosAndPhotos from './VideosAndPhotos';
import PricingAndOthers from './PricingAndOthers';

const PropertyForm = () => {
    const [activeTab, setActiveTab] = useState('Basic-Details');

    const renderContent = () => {
        switch (activeTab) {
            case 'Basic-Details':
                return <BasicDetails setActiveTab={setActiveTab} />;
            case 'Location-Details':
                return <LocationDetails setActiveTab={setActiveTab} />;
            case 'Property-Profile':
                return <PropertyProfile setActiveTab={setActiveTab} />;
            case 'Videos&Photos':
                return <VideosAndPhotos setActiveTab={setActiveTab} />;
            case 'Pricing&Others':
                return <PricingAndOthers setActiveTab={setActiveTab} />;
            default:
                return <BasicDetails setActiveTab={setActiveTab} />;
        }
    };

    return (
        <div className="add-new-property-full-container container-fluid">
            <div className="add-new-property-container container">
                <div className="addNewPropertyHead">
                    <h2>Add New Property</h2>
                    <p>We are glad to see you again!</p>
                </div>
                <div className='addNewPropertybtn container'>
                    <div className="tabs">
                        <ul className=''>
                            <li
                                className={`tab-item ${activeTab === 'Basic-Details' ? 'active' : ''}`}
                                onClick={() => setActiveTab('Basic-Details')}
                            >
                                Basic Details
                            </li>
                            <li
                                className={`tab-item ${activeTab === 'Location-Details' ? 'active' : ''}`}
                                onClick={() => setActiveTab('Location-Details')}
                            >
                                Location Details
                            </li>
                            <li
                                className={`tab-item ${activeTab === 'Property-Profile' ? 'active' : ''}`}
                                onClick={() => setActiveTab('Property-Profile')}
                            >
                                Property Profile
                            </li>
                            <li
                                className={`tab-item ${activeTab === 'Videos&Photos' ? 'active' : ''}`}
                                onClick={() => setActiveTab('Videos&Photos')}
                            >
                                Video & Photos
                            </li>
                            <li
                                className={`tab-item ${activeTab === 'Pricing&Others' ? 'active' : ''}`}
                                onClick={() => setActiveTab('Pricing&Others')}
                            >
                                Pricing & Others
                            </li>
                        </ul>
                        <hr />
                    </div>
                    <div className="render-container">
                        {renderContent()}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PropertyForm;
