import React, { Fragment } from 'react'
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import ReactOwlCarousel from 'react-owl-carousel';
import house1 from '../../Assets/images/house1.jpg';
import house2 from '../../Assets/images/House2.jpg';
import house3 from '../../Assets/images/house3.jpg';
import house4 from '../../Assets/images/House4.jpg';

function DicoverFeatureProperty() {
    const options = {
        loop: true,
        autoplay: false,
        autoplayTimeout: 1500,
        nav: true,
        dots: true,
        responsive: {
            0: {
                items: 1
            },
            600: {
                items: 1
            },
            1000: {
                items: 3
            },
            1200: {
                items: 3
            }
        }
    };
    return (
        <Fragment>
            <div className="container-fluid discover-feature-property-full-container bg-light mt-4 rounded-4">
                <div className="container-fluid">
                    <div className="conatiner" style={{ padding: '10px' }}>
                        <section className="discover-section">
                            <h1>Discover Our Featured Listings</h1>
                            <p>
                                Aliquam lacinia diam quis lacus euismod
                            </p>
                        </section>
                        {/* Owl Carousel */}
                        <section className='discover-owl-section'>
                            <ReactOwlCarousel className='owl-theme' {...options}>
                                <div className='item'>
                                    <div class="card m-2">
                                        <img src={house1} class="card-img" alt="..." />
                                        <div className="card-header">
                                            Card title
                                        </div>
                                        <div className="card-body">
                                            <div className="card-title">
                                                Last updated 3 mins ago
                                            </div>
                                            <div className="card-text">
                                                This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='item'>
                                    <div class="card m-2">
                                        <img src={house2} class="card-img" alt="..." />
                                        <div className="card-header">
                                            Card title
                                        </div>
                                        <div className="card-body">
                                            <div className="card-title">
                                                Last updated 3 mins ago
                                            </div>
                                            <div className="card-text">
                                                This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='item'>
                                    <div class="card m-2">
                                        <img src={house3} class="card-img" alt="..." />
                                        <div className="card-header">
                                            Card title
                                        </div>
                                        <div className="card-body">
                                            <div className="card-title">
                                                Last updated 3 mins ago
                                            </div>
                                            <div className="card-text">
                                                This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='item'>
                                    <div class="card m-2">
                                        <img src={house4} class="card-img" alt="..." />
                                        <div className="card-header">
                                            Card title
                                        </div>
                                        <div className="card-body">
                                            <div className="card-title">
                                                Last updated 3 mins ago
                                            </div>
                                            <div className="card-text">
                                                This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </ReactOwlCarousel>
                        </section>
                    </div>
                </div>

            </div>
        </Fragment>
    )
}

export default DicoverFeatureProperty
