import React, { Fragment } from 'react';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import ReactOwlCarousel from 'react-owl-carousel';
import building1 from '../../Assets/images/building1.jpg';
import building2 from '../../Assets/images/building2.jpg';
import building3 from '../../Assets/images/building3.jpg';
import building4 from '../../Assets/images/building4.jpg';
import building5 from '../../Assets/images/building5.jpg';
import { RiArrowRightUpLine } from 'react-icons/ri';

function ByCity() {
    const options = {
        loop: false,
        autoplay: false,
        autoplayTimeout: 1500,
        nav: true,
        dots: false,
        responsive: {
            0: {
                items: 1
            },
            600: {
                items: 1
            },
            1000: {
                items: 3
            },
            1200: {
                items: 4
            }
        }
    };
    return (
        <Fragment>
            <div className="bycity-full-container container-fluid py-5">
                <div className="conatiner" style={{ padding: '20px' }}>
                    <div className="row">
                        <div className="col-lg-6">
                            <section className="bycity-section">
                                <h1>Properties by Cities</h1>
                                <p>
                                    Aliquam lacinia diam quis lacus euismod
                                </p>
                            </section>
                        </div>
                        <div className="col-lg-6 text-lg-end">
                            <section className="">
                                <button className='btn btn-outline-dark'>See All Cities <RiArrowRightUpLine /></button>
                            </section>
                        </div>
                    </div>
                    {/* Owl Carousel */}
                    <section className='bycity-owl-section'>
                        <div className="container">
                            <ReactOwlCarousel className='owl-theme' {...options}>
                                <div className='item'>
                                    <div class="card">
                                        <img src={building1} class="card-img" alt="..." />
                                        <div className="card-img-overlay">
                                            Card title
                                            <div className="card-title">
                                                <button className='btn btn-primary'>Featured</button>
                                            </div>
                                            <div className="card-text">
                                                This is a wider card  is a little bit longer.
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div className='item'>
                                    <div class="card">
                                        <img src={building2} class="card-img" alt="..." />

                                        <div className="card-img-overlay">
                                            Card title
                                            <div className="card-title">
                                                <button className='btn btn-primary'>Featured</button>
                                            </div>
                                            <div className="card-text">
                                                This is a wider card  is a little bit longer.
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div className='item'>
                                    <div class="card">
                                        <img src={building3} class="card-img" alt="..." />

                                        <div className="card-img-overlay">
                                            Card title
                                            <div className="card-title">
                                                <button className='btn btn-primary'>Featured</button>
                                            </div>
                                            <div className="card-text">
                                                This is a wider card  is a little bit longer.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='item'>
                                    <div class="card">
                                        <img src={building4} class="card-img" alt="..." />

                                        <div className="card-img-overlay">
                                            Card title
                                            <div className="card-title">
                                                <button className='btn btn-primary'>Featured</button>
                                            </div>
                                            <div className="card-text">
                                                This is a wider card  is a little bit longer.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='item'>
                                    <div class="card">
                                        <img src={building5} class="card-img" alt="..." />

                                        <div className="card-img-overlay">
                                            Card title
                                            <div className="card-title">
                                                <button className='btn btn-primary'>Featured</button>
                                            </div>
                                            <div className="card-text">
                                                This is a wider card  is a little bit longer.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </ReactOwlCarousel>
                        </div>
                    </section>
                </div>
            </div>
        </Fragment>
    )
}

export default ByCity
