// VisitorChart.js
import React, { Fragment, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const DashboardGraph = () => {
    const [timeFrame, setTimeFrame] = useState('daily');

    const data = {
        daily: [30, 50, 40, 60, 70, 90, 120],
        weekly: [200, 300, 250, 400, 450, 500, 600],
        monthly: [800, 1000, 1200, 1500, 1600, 1800, 2000, 5900, 6641, 8675, 9540, 11000],
    };

    const labels = {
        daily: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
        weekly: ['Week 1', 'Week 2', 'Week 3', 'Week 4', 'Week 5', 'Week 6', 'Week 7'],
        monthly: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
    };

    const chartData = {
        labels: labels[timeFrame],
        datasets: [
            {
                label: `Visitors (${timeFrame})`,
                data: data[timeFrame],
                backgroundColor: 'rgba(12,44,67,.4)',
                borderColor: 'rgba(0, 0, 0, 1)',
                borderWidth: 1,
            },
        ],
    };

    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: true,
                text: `Number of Visitors (${timeFrame.charAt(0).toUpperCase() + timeFrame.slice(1)})`,
            },
        },
    };

    return (
        <Fragment>
            <div className="container-fluid property-view-full-container bg-white mt-4 rounded-4">
                <div className='property-view-row'>
                    <h6>Property Views</h6>
                    <div>
                        <button className='btn btn-outline-dark ms-2' onClick={() => setTimeFrame('daily')}>Daily</button>
                        <button className='btn btn-outline-dark ms-2' onClick={() => setTimeFrame('weekly')}>Weekly</button>
                        <button className='btn btn-outline-dark ms-2' onClick={() => setTimeFrame('monthly')}>Monthly</button>
                    </div>
                    <Bar data={chartData} options={options} />
                </div>
            </div>
        </Fragment>
    );
};

export default DashboardGraph;

